import { Injectable } from '@angular/core';
import { BitfStorageService } from '@bitf/core/services/storage/bitf-storage.service';
import { Storage } from '@models';

@Injectable({
  providedIn: 'root',
})
export class StorageService extends BitfStorageService<Storage> {
  constructor() {
    super({
      nativeStorage: localStorage,
      storageKey: 'storage',
      storageClass: Storage,
    });
  }
}
