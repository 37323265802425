import { Component, Input, OnChanges } from '@angular/core';
import { FileModel } from '@models';

@Component({
  selector: 'prada-main-image',
  templateUrl: './main-image.component.html',
  styleUrls: ['./main-image.component.scss'],
})
export class MainImageComponent implements OnChanges {
  @Input()
  image: FileModel;
  @Input()
  backgroundSize = 'contain';
  @Input()
  rounded = false;
  imageViewStyle = {};

  constructor() {}

  ngOnChanges() {
    if (!this.image) {
      this.image = new FileModel();
    }
    this.imageViewStyle = {
      backgroundImage: 'url(' + this.image.previewUrl + ')',
      backgroundSize: this.image.isPlaceholderFile ? 'contain' : this.backgroundSize,
    };
  }
}
