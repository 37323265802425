import { Component, OnInit } from '@angular/core';

import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';
// tslint:disable-next-line:max-line-length
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';

import { IBitfMenuItem } from '@interfaces';
import { APP_VERSION, BUILD_DATE } from '@env/version';
import { AppSessionService, UiRoleManagerService } from '@services';
import { EBitfCloseEventStatus, ERoleActions } from '@enums';

@Component({
  selector: 'prada-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent implements OnInit {
  appVersion = APP_VERSION;
  appBuildAt = BUILD_DATE;

  menuItems: Array<IBitfMenuItem> = [
    {
      icon: 'assignment',
      label: bitfToTranslate('WEB.MAIN_MENU.PROJECTS'),
      url: '/projects',
    },
    // {
    //   icon: 'insert_invitation',
    //   label: bitfToTranslate('WEB.MAIN_MENU.REAL_TIME_SCHEDULING'),
    //   // url: '/real-time-campaing',
    //   url: '',
    // },
    // {
    //   icon: 'list_alt',
    //   label: bitfToTranslate('WEB.MAIN_MENU.APPROVAL_LIST'),
    //   // url: '/approval-list',
    //   url: '',
    // },
  ];

  constructor(
    public appSessionService: AppSessionService,
    private mSidenavService: BitfMatSidenavService,
    private uiRoleManagerService: UiRoleManagerService
  ) {}

  ngOnInit() {
    if (this.uiRoleManagerService.canI(ERoleActions.VIEW_SETTINGS)) {
      this.menuItems.push({
        icon: 'settings',
        label: bitfToTranslate('COMMON.LABEL.SETTINGS'),
        url: '/settings',
      });
    }
  }

  closeSidenav() {
    this.mSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }
}
