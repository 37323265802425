import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';
import { DateAdapter } from '@angular/material/core';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { HeaderLogoComponent } from './components/header-logo/header-logo.component';
import { FormTreeSelectComponent } from './components/form-tree-select/form-tree-select.component';
import { TitleToolbarComponent } from './components/title-toolbar/title-toolbar.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { FileUploaderComponent } from '@web/shared/components/file-uploader/file-uploader.component';
import { GanttComponent } from './components/gantt/gantt.component';
import { BitfBytesPipe } from '@bitf/pipes/bitf-bytes.pipe';
import { MainImageComponent } from './components/main-image/main-image.component';
import { HeaderComponent } from './components/header/header.component';
import { MomentsDialogComponent } from './components/moments-dialog/moments-dialog.component';
import { HalfPieChartComponent } from './components/half-pie-chart/half-pie-chart.component';
import { DateAdapterService } from '@web/core/services/date-adapter.service';

// tslint:disable:max-line-length
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfMatFormOptionComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-option/bitf-mat-form-option.component';
import { BitfMatFormItemComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-item/bitf-mat-form-item.component';

import { ProjectMobileMenuComponent } from '@web/modules/projects/project-mobile-menu/project-mobile-menu.component';
import { FileUploaderDialogComponent } from './components/file-uploader-dialog/file-uploader-dialog.component';
import { MenuMobileSelectorComponent } from './components/menu-mobile-selector/menu-mobile-selector.component';
import { ProductComponent } from './components/product/product.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';

import { BitfDynamicLocaleService } from '@bitf/core/services/locale/bitf-dynamic-locale.service';
import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfDndFileComponent } from '@bitf/core/components/ui/dnd-file/bitf-dnd-file.component';
import { BitfMatSortComponent } from '@bitf/core/components/ui/sort/material/bitf-mat-sort.component';
import { BitfMatSearchComponent } from '@bitf/core/components/ui/search/material/bitf-mat-search.component';
import { BitfMatSwitchComponent } from '@bitf/core/components/ui/switch/material/bitf-mat-switch.component';
import { BitfMatPaginatorComponent } from '@common/libs/bitforce/core/components/ui/paginator/material/bitf-mat-paginator.component';
import {
  azureBlobStorageFactory,
  BLOB_STORAGE_TOKEN,
} from '@common/libs/bitforce/core/services/azure/types/token';
// tslint:enable:max-line-length

const SHARED_MODULES = [
  CommonSharedModule,
  RouterModule,
  MATERIAL_MODULES,
  CDK_MODULES,
  Ng2GoogleChartsModule,
];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatOkCancelDialogComponent,
  HeaderLogoComponent,
  FormTreeSelectComponent,
  BitfMatFormOptionComponent,
  TitleToolbarComponent,
  BitfMatFormItemComponent,
  ProjectMobileMenuComponent,
  FileListComponent,
  InputDialogComponent,
  FileUploaderComponent,
  BitfDndFileComponent,
  FileUploaderDialogComponent,
  GanttComponent,
  BitfMatSortComponent,
  MainImageComponent,
  BitfMatSearchComponent,
  HeaderComponent,
  MomentsDialogComponent,
  MenuMobileSelectorComponent,
  ProductComponent,
  HalfPieChartComponent,
  PieChartComponent,
  BitfMatSwitchComponent,
  BitfMatPaginatorComponent,
];
const SHARED_DIRECTIVES = [];
const SHARED_PIPES = [BitfBytesPipe];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: DateAdapter, useClass: DateAdapterService },
    {
      provide: LOCALE_ID,
      deps: [BitfDynamicLocaleService],
      useFactory: (dynamicLocaleService: BitfDynamicLocaleService) => dynamicLocaleService.defaultLocale.code,
    },
    // {provide: 'googleChartsVersion', useValue: '46'},
    { provide: 'mapsApiKey', useValue: 'AIzaSyDDTYms7Inqjxyw21qXkqBdKGlYM50jkMs' },
    { provide: BLOB_STORAGE_TOKEN, useFactory: azureBlobStorageFactory },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
