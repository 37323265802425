import { SuperModel } from './super-model.model';

import { environment } from '@env/environment';

export class FileModel extends SuperModel {
  name: string;
  status: number;
  url: string;
  type: string;
  mainImage: boolean;
  uploadedBy: string;
  uploadedAt: string;
  _previewUrl: string;
  downloadUrl: string;
  isValid: boolean;
  errors?: any;
  isPlaceholderFile = false;

  constructor(data: any = {}) {
    super(data);

    if (data.isValid === undefined) {
      this.isValid = true;
    }
  }

  set previewUrl(url: string) {
    this._previewUrl = url;
  }

  get previewUrl() {
    try {
      if (this._previewUrl && this.isUrl(this._previewUrl)) {
        return `${this._previewUrl}`;
      } else {
        this.isPlaceholderFile = true;
        // TODO @ale add replace prada with the brand slug from localstorage
        return '/assets/images/placeholders/sintra.svg';
      }
    } catch (error) {
      this.isPlaceholderFile = true;
      // TODO @ale add replace prada with the brand slug from localstorage
      return '/assets/images/placeholders/sintra.svg';
    }
  }

  isUrl(string): boolean {
    try {
      const isUrl = new URL(string);
      if (isUrl) {
        return true;
      } else {
        return false;
      }
    } catch (_) {
      return false;
    }
  }

  get extension() {
    return this.name && this.name.split('.').pop();
  }

  get isImage() {
    return this.extension && this.extension.match(/^jpg$|^jpeg$|^png$|^gif$|^bmp$|^ico$|^tif|^emf$/i);
  }

  get isGif() {
    return this.extension && this.extension.match(/^gif$/i);
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      status: this.status,
      url: this.url,
      tyep: this.type,
      mainImage: this.mainImage,
      uploadedBy: this.uploadedBy,
      uploadedAt: this.uploadedAt,
      previewUrl: this._previewUrl,
      downloadUrl: this.downloadUrl,
    };
  }
}
