import { SuperModel } from './super-model.model';
import { FileModel } from './file-model.model';
import { Social } from './social.model';
export class Influencer extends SuperModel {
  name: string;
  age: number;
  birthDate: string;
  nationality: string;
  occupation: string;
  previousCollaborationWithPradaGroup: string;
  link: string;
  socialNetworkId: number;
  picture: FileModel;
  social: Social;
  isCustom: boolean;

  constructor(data: any = {}) {
    super(data);

    if (data.picture) {
      this.picture = new FileModel(data.picture);
    } else {
      this.picture = new FileModel();
    }

    if (data.social) {
      this.social = new Social(data.social);
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      age: this.age,
      birthDate: this.birthDate,
      picture: this.picture,
      nationality: this.nationality,
      occupation: this.occupation,
      previousCollaborationWithPradaGroup: this.previousCollaborationWithPradaGroup,
      socialNetworkId: this.socialNetworkId,
      link: this.link,
      social: this.social,
      isCustom: this.isCustom,
    };
  }
}
