import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
import { EBitfAuthState } from '@bitf/enums';
import { BitfFormItem } from '@bitf/core/models/bitf-form-item.model';

import { AuthService, StorageService, StoreService } from '@services';
import { Brand } from '@models';
import { environment } from '@env/environment';

@Component({
  selector: 'prada-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  authState: EBitfAuthState = EBitfAuthState.TO_BE_LOGGED;
  bitfEAuthState = EBitfAuthState;
  form: FormGroup;
  brands: BitfFormItem;
  backgroundImage: string;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private storeService: StoreService
  ) {}

  @BitfTryCatch()
  ngOnInit() {
    this.backgroundImage = environment.apiUrl + 'configs/background-image';

    this.subscriptions.add(
      this.authService.authState$.subscribe((authState: EBitfAuthState) => {
        this.authState = authState;
        console.log('logged', EBitfAuthState.LOGGED);
        if (authState === EBitfAuthState.LOGGED) {
          console.log(this.storeService.store);
          this.brands = this.storeService.store.user.formItemsConfig.brands;
          if (this.brands.options.length > 1) {
            this.initForm();
          } else if (this.brands.options.length) {
            const slectedBrand = this.brands.options[0];
            const brand = new Brand({
              id: slectedBrand.id,
              label: slectedBrand.label,
              slug: slectedBrand.metaData.slug,
            });
            this.storageService.data = {
              selectedBrand: brand,
            };
            this.storeService.store.brand = brand;
            // Present to the user the login progress
            setTimeout(() => {
              this.authService.handleRedirect();
            }, 2000);
          }
        }
      })
    );
    this.authService.handleAuthentication();
  }

  retrySignIn() {
    this.authService.auth0Login();
  }

  initForm() {
    this.form = this.formBuilder.group({
      brandId: ['', Validators.required],
    });
    const brandId = bitfGetProp(this.storageService.data, 'selectedBrand', 'id');
    if (brandId) {
      this.form.setValue({ brandId });
    }
  }

  selectBrand() {
    const slectedBrand = this.brands.options.find(b => b.id === this.form.value.brandId);
    const brand = new Brand({
      id: slectedBrand.id,
      label: slectedBrand.label,
      slug: slectedBrand.metaData.slug,
    });
    this.storageService.data = {
      selectedBrand: brand,
    };
    this.storeService.store.brand = brand;

    this.authService.handleRedirect();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
