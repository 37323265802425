import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';

// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';

import { InputDialogComponent } from '@web/shared/components/input-dialog/input-dialog.component';
import { MomentsDialogComponent } from '@web/shared/components/moments-dialog/moments-dialog.component';
import { BITF_VALIDATION_MESSAGES } from '@common/constants';

import { environment } from '@env/environment';

const auth0ClientId = {
  development: 'DQlXhk45uljUJNnFmk720rfvSfDbWm44',
  'remote-development': 'DQlXhk45uljUJNnFmk720rfvSfDbWm44',
  staging: 'DQlXhk45uljUJNnFmk720rfvSfDbWm44',
  production: 'DQlXhk45uljUJNnFmk720rfvSfDbWm44',
};

export const WEB_CONSTANTS = {
  fullScreenMessageComponent: null,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  inputDialogComponent: InputDialogComponent,
  momentsDialogComponent: MomentsDialogComponent,
  pageSizeOptions: [8, 16, 32, 80],
  lightGreen: '#01afa0',
  red: '#fd5754',
  yellow: '#f4c439',
  darkGrey: '#303d40',
  blueGrey: '#546062',
  lightGrey: '#f2f2f2',
  lightBlue: '#7fcee8',
  green: '#0074d8',
  VALIDATION_MESSAGES: {
    ...BITF_VALIDATION_MESSAGES,
  },
  auth0Config: {
    clientID: auth0ClientId[environment.name] || auth0ClientId.development,
    returnTo: `${location.origin}/sign-in`,
    domain: 'dev-lda6xpxv.auth0.com',
    responseType: 'token',
    redirectUri: `${location.origin}/sign-in`,
    scope: 'openid',
    audience: 'https://dev-lda6xpxv.auth0.com/api/v2/',
  },
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, WEB_CONSTANTS);
