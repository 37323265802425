import { Injectable, Injector } from '@angular/core';

import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
import { BitfAuthGuard } from '@bitf/core/services/guards/bitf-auth.guard';

import { StoreService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BitfAuthGuard {
  constructor(protected injector: Injector, public storeService: StoreService) {
    super(injector);

    const brandId = bitfGetProp(this.storageService.data, 'selectedBrand', 'id');
    if (!brandId) {
      this.router.navigate(['/sign-in']);
    } else {
      this.storeService.store.brand = bitfGetProp(this.storageService.data, 'selectedBrand');
    }
  }
}
