import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { AppSessionService } from '@services';

@Component({
  selector: 'prada-menu-mobile-selector',
  templateUrl: './menu-mobile-selector.component.html',
  styleUrls: ['./menu-mobile-selector.component.scss'],
})
export class MenuMobileSelectorComponent implements OnInit {
  @Input()
  title: string;

  constructor(public appSessionService: AppSessionService) {}

  ngOnInit() {}
}
