import { SuperModel } from './super-model.model';
import { ChannelGroup } from '@models';

export interface ActivityReport {
  unchecked: number;
  approved: number;
  rejected: number;
  totalActivities: number;
}

export class ActivityOverview extends SuperModel {
  channelGroup: ChannelGroup;
  activities: ActivityReport;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      channelGroup: this.channelGroup,
      activities: this.activities,
    };
  }
}
