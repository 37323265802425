const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
export const bitfBytesPipeFn = (bytes: number = 0, precision: number = 2): string => {
  if (typeof bytes === 'string') {
    bytes = Number(bytes);
  }

  if (!isFinite(bytes) || isNaN(bytes)) {
    return '?';
  }

  let unit = 0;

  while (bytes >= 1024) {
    bytes /= 1024;
    unit++;
  }

  if (unit === 0) {
    precision = 0;
  }

  return `${bytes.toFixed(+precision)} ${units[unit]}`;
};
