import { SuperModel } from './super-model.model';
import {
  Activity,
  CelebrityActivity,
  EventActivity,
  GiftingVicActivity,
  InfluencerActivity,
  MediaPlanningActivity,
  PopupStoreActivity,
  PradaChannelActivity,
  PressScheduleActivity,
  WholesaleActivity,
} from '@models';
import { EProjectsRoutes } from '@enums';

export class Channel extends SuperModel {
  label: string;
  slug: string;
  channelGroupSlug: string;
  totalActivities: number;
  activities: Activity[];

  constructor(data: any = {}) {
    super(data);

    if (this.activities) {
      switch (this.channelGroupSlug) {
        case EProjectsRoutes.CELEBRITIES:
          this.activities = this.activities.map(activity => new CelebrityActivity(activity));
          break;
        case EProjectsRoutes.EVENTS:
          this.activities = this.activities.map(activity => new EventActivity(activity));
          break;
        case EProjectsRoutes.GIFTING_VIC:
          this.activities = this.activities.map(activity => new GiftingVicActivity(activity));
          break;
        case EProjectsRoutes.INFLUENCERS:
          this.activities = this.activities.map(activity => new InfluencerActivity(activity));
          break;
        case EProjectsRoutes.MEDIA_PLANNING:
          this.activities = this.activities.map(activity => new MediaPlanningActivity(activity));
          break;
        case EProjectsRoutes.POPUP_STORE:
          this.activities = this.activities.map(activity => new PopupStoreActivity(activity));
          break;
        case EProjectsRoutes.PRADA_CHANNELS:
          this.activities = this.activities.map(activity => new PradaChannelActivity(activity));
          break;
        case EProjectsRoutes.PRESS_SCHEDULE:
          this.activities = this.activities.map(activity => new PressScheduleActivity(activity));
          break;
        case EProjectsRoutes.WHOLESALE:
          this.activities = this.activities.map(activity => new WholesaleActivity(activity));
          break;
        default:
          this.activities = this.activities.map(activity => new Activity(activity));
          break;
      }
    }
  }

  get serialised() {
    return {
      id: this.id,
      label: this.label,
      totalActivities: this.totalActivities,
      activities: this.activities,
    };
  }
}
