// import { User } from '@common/core/models/user.model';
import justClone from 'just-clone';

export abstract class SuperModel {
  id?: number;
  createdAt?: string | Date;
  modifiedAt?: string | Date;
  // createdBy: string | User;
  // modifiedBy: string | User;

  constructor(object: any = {}) {
    Object.assign(this, object);
    if (!object) {
      return;
    }
    // if (typeof object.createdBy === 'object') {
    //   this.createdBy = new User(object.createdBy);
    // }
    // if (typeof object.modifiedBy === 'object') {
    //   this.modifiedBy = new User(object.modifiedBy);
    // }
  }

  abstract readonly serialised;

  get clone() {
    return justClone(this.serialised);
  }
}
