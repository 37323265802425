<table mat-table
  [dataSource]="dataSource"
  class="w-100"
  *ngIf="dataSource.data.length">

  <!-- Type Preview -->
  <ng-container matColumnDef="preview">
    <th mat-header-cell
      *matHeaderCellDef>{{ 'COMMON.FILE.PREVIEW' | translate}} </th>
    <td mat-cell
      class="text-align-center"
      *matCellDef="let file">
      <img [src]="file.previewUrl"
        *ngIf="file.isImage"
        class="mr-2 preview-image">
      <mat-icon *ngIf="!file.isImage">insert_drive_file</mat-icon>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell
      *matHeaderCellDef> Name </th>
    <td mat-cell
      *matCellDef="let file"> {{file.name}} </td>
  </ng-container>

  <!-- Upload date Column -->
  <ng-container matColumnDef="upload_date">
    <th mat-header-cell
      *matHeaderCellDef
      class="d-none d-md-table-cell"> {{ 'COMMON.FILE.UPLOAD_DATE' | translate}} </th>
    <td mat-cell
      *matCellDef="let file"
      class="d-none d-md-table-cell"> {{file.uploadedAt | date}} </td>
  </ng-container>

  <!-- Upload by Column -->
  <ng-container matColumnDef="upload_by">
    <th mat-header-cell
      class="d-none d-md-table-cell"
      *matHeaderCellDef> {{ 'COMMON.FILE.UPLOAD_BY' | translate}} </th>
    <td mat-cell
      class="d-none d-md-table-cell"
      *matCellDef="let file"> {{file.uploadedBy}} </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell
      *matHeaderCellDef> </th>
    <td mat-cell
      *matCellDef="let file">
      <div class="d-flex"
        *bitfUiRoleManager="{action: ERoleActions.EDIT_FILE}">
        <button mat-icon-button
          (click)="onAction('delete', file)"
          class="d-none d-md-table-cell">
          <mat-icon>delete</mat-icon>
        </button>
        <!-- <button mat-icon-button
          (click)="onAction('share', file)">
          <mat-icon>share</mat-icon>
        </button> -->
        <button mat-icon-button
          (click)="onAction('download', file)">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row
    *matHeaderRowDef="sectionGroupsTableColumn"></tr>
  <tr mat-row
    *matRowDef="let row; columns: sectionGroupsTableColumn;"></tr>
</table>