import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfFormItemConfig } from '@bitf/core/models/bitf-form-item-config.model';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

import { IBitfApiResponse, IBitfApiRequest } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class EventTypesService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('event-types', injector, 'https://localhost:3002/', 'mockDbParser');
    super('event-types', injector);
  }

  getFormItemConfig(): Observable<IBitfApiResponse<BitfFormItemConfig>> {
    return super.request({
      method: 'GET',
      relation: 'form-items-config',
    } as IBitfApiRequest);
  }
}
