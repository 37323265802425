import { SuperModel } from './super-model.model';

export class TargetAndResult extends SuperModel {
  label: string;
  target: number;
  real: number;
  readonlyTarget: boolean;
  readonlyReal: boolean;
  delta?: number;
  sign?: string;
  class?: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      target: this.target,
      real: this.real,
      readonlyReal: this.readonlyReal,
      readonlyTarget: this.readonlyTarget,
      delta: this.delta,
    };
  }
}
