<h2 mat-dialog-title>{{ dialogData.title | translate }}</h2>
<mat-dialog-content class="justify-content-center">
  <mat-divider></mat-divider>

  <mat-form-field appearance="outline"
    class="pt-3">
    <mat-label>{{ dialogData.placeholder }}</mat-label>
    <input matInput
      placeholder="{{dialogData.placeholder | translate}}"
      [formControl]="formControl">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="row"
  [ngClass]="!dialogData.cancelText || !dialogData.okText ? 'justify-content-center' : 'justify-content-end'">
  <button mat-flat-button
    (click)="onCancel()"
    *ngIf="dialogData.cancelText">
    {{ dialogData.cancelText | translate | uppercase }}
  </button>

  <button mat-flat-button
    (click)="onOk()"
    class="ml-3"
    [disabled]="!formControl.valid"
    color="primary"
    [mat-dialog-close]="true"
    *ngIf="dialogData.okText">
    {{ dialogData.okText | translate | uppercase }}
  </button>
</mat-dialog-actions>