<div class="position-relative pt-2">
  <google-chart [data]="halfPieChartData"></google-chart>

  <div class="left mat-caption">0 {{unitOfMeasure}}</div>
  <div class="center mat-h3 m-0">
    {{ realLabel}} </div>
  <div class="center-top mat-h1 m-0"></div>
  <div class="center-top-value m-0">
    {{ targetLabel }}
  </div>
  <div class="right mat-caption">
    {{endScaleLabel}}</div>
</div>