import { Activity } from './activity.model';

export class WholesaleActivity extends Activity {
  platform: string;
  format: string;
  country: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      ...new Activity(this).serialised,
      platform: this.platform,
      format: this.format,
      country: this.country,
    };
  }
}
