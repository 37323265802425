import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { numberMask } from '@bitf/utils/bitf-form.utils';
import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

import { IBitfApiRequest, IBitfCloseEvent } from '@interfaces';
import { ActivitiesService, DialogsService, ToastMessagesService, LoaderService } from '@services';

import { Product, FileModel, Activity } from '@models';
import { CONSTANTS } from '@constants';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { EBitfCloseEventStatus, EBitfToastHorizontalPosition, EBitfUiMessageType } from '@bitf/enums';

@Component({
  selector: 'prada-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @Input()
  activity: Activity;

  @Output()
  changes: EventEmitter<boolean> = new EventEmitter<boolean>();

  products: Product[] = [];
  form: FormGroup;
  public numberMask = numberMask({ decimalLimit: 2, allowDecimal: true });

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private activitiesService: ActivitiesService,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private bitfErrorHandlerService: BitfErrorHandlerService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit() {
    this.products = this.activity.products;
    this.initForm();
  }

  private initForm() {
    const formArray: FormArray = new FormArray([]);
    this.products.forEach(item => formArray.push(this.createFormGroup(item)));

    this.form = this.formBuilder.group({
      items: formArray,
    });

    if (this.products.length === 0) {
      this.addProduct();
    }
  }

  private createFormGroup(product: Product): FormGroup {
    const group = {
      id: product.id,
      code: product.code,
      category: product.category,
      price: product.price,
      file: product.file,
      singleUploadLabel: product.file.id
        ? this.translateService.instant('COMMON.LABEL.EDIT_PHOTO')
        : undefined,
      model: product,
    };
    return this.formBuilder.group(group);
  }

  getProductById(id: number): Product {
    return this.products.find(item => item.id === id);
  }

  saveProduct(product: FormGroup, index: number) {
    this.changes.emit(true);
    let apiRequest: IBitfApiRequest = {
      method: 'POST',
      id: this.activity.id,
      relation: 'products',
      body: product.value,
      isBodyRaw: true,
    };
    if (product.value.id) {
      apiRequest = {
        ...apiRequest,
        method: 'PATCH',
        relationId: product.value.id,
      };
    }
    this.activitiesService.request<Product>(apiRequest).subscribe(productResponse => {
      this.showSavedMessage();
      const items = this.form.get('items') as FormArray;
      items.at(index).patchValue(this.createFormGroup(productResponse.content).value);
    });
  }

  addProduct() {
    const items = this.form.get('items') as FormArray;
    items.push(this.createFormGroup(new Product()));
  }

  onStartMainImageFileUpload() {
    this.loaderService.show();
  }

  onMainImageFileUploaded(files: FileModel | FileModel[], productFormGroup: FormGroup) {
    if (files && (files as FileModel).isValid) {
      this.changes.emit(true);
      productFormGroup.patchValue({ file: files as FileModel });
    }
    this.loaderService.hide();
  }

  deleteProduct(productFormGroup: FormGroup, index: number) {
    const items = this.form.get('items') as FormArray;

    if (!productFormGroup.value.id) {
      items.removeAt(index);
      return;
    }
    const dialogRef = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
      width: 'auto',
      maxWidth: '800px',
      data: {
        title: bitfToTranslate('COMMON.DIALOG.DELETE_PRODUCT.TITLE'),
        message: this.translateService.instant('COMMON.DIALOG.DELETE_PRODUCT.DESCRIPTION', {
          value: productFormGroup.value.code,
        }),
        cancelText: bitfToTranslate('COMMON.BUTTON.NO'),
        okText: bitfToTranslate('COMMON.BUTTON.OK'),
      },
    });
    dialogRef.afterClosed().subscribe((result: IBitfCloseEvent<any>) => {
      if (result && result.status === EBitfCloseEventStatus.OK) {
        items.removeAt(index);
        this.activitiesService
          .delete({ id: this.activity.id, relation: 'products', relationId: productFormGroup.value.id })
          .subscribe(
            () => {
              this.changes.emit(true);
              this.products.splice(index, 1);
            },
            error => {
              this.showErrorMessage();
              this.bitfErrorHandlerService.handleError(error);
              this.revert(index, productFormGroup);
            }
          );
      }
    });
  }

  private revert(index: number, productFormGroup: FormGroup) {
    const items = this.form.get('items') as FormArray;
    items.insert(index, productFormGroup);
  }

  private showErrorMessage() {
    const translations = this.translateService.instant([bitfToTranslate('COMMON.TOAST_MESSAGES.ERROR')]);
    this.toastMessagesService.show({
      type: EBitfUiMessageType.ERROR,
      title: translations['COMMON.TOAST_MESSAGES.ERROR'],
      horizontalPosition: EBitfToastHorizontalPosition.LEFT,
    });
  }

  private showSavedMessage() {
    const translations = this.translateService.instant([
      bitfToTranslate('COMMON.TOAST_MESSAGES.SAVED_MESSAGE'),
    ]);
    this.toastMessagesService.show({
      type: EBitfUiMessageType.SUCCESS,
      title: translations['COMMON.TOAST_MESSAGES.SAVED_MESSAGE'],
      horizontalPosition: EBitfToastHorizontalPosition.LEFT,
    });
  }
}
