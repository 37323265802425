import { OnInit, Input, OnDestroy } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { ApiCallStateService } from '@services';
import { EApiRequestPartKeys, EBitfApiCallStateActions, BitfESwitchType } from '@enums';

export class BitfSwitchComponent implements OnInit, OnDestroy {
  @Input()
  apiCallStateName;

  @Input()
  apiRequestPartKey = EApiRequestPartKeys.FILTERS;

  @Input()
  formKey;

  @Input()
  componentType: BitfESwitchType;

  @Input()
  label;

  BitfESwitchType = BitfESwitchType;
  checked = false;
  requestPart: BitfApiRequestPart;
  subscription: Subscription = new Subscription();

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    this.requestPart = this.apiCallStateService.getRequestPart(this.apiCallStateName, this.apiRequestPartKey);
    this.subscription = this.apiCallStateService.getStateStore$(this.apiCallStateName).subscribe(() => {
      this.patchWithApiRequestPart();
    });
    this.patchWithApiRequestPart();
  }

  private patchWithApiRequestPart() {
    const formValue = this.requestPart.formValue;
    if (formValue && formValue[this.formKey]) {
      this.checked = formValue[this.formKey];
    } else {
      this.checked = false;
    }
  }

  onSwitchChange(event: MatSlideToggleChange) {
    this.updateApiCallState(event.checked);
  }

  private updateApiCallState(value: boolean) {
    this.requestPart.formValue = {
      ...this.requestPart.formValue,
      [this.formKey]: value,
    };
    const apiCallStateRequest = this.apiCallStateService.getApiRequest(this.apiCallStateName);
    apiCallStateRequest.page = 0;

    this.apiCallStateService.setStore(() => {}, this.apiCallStateName);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
