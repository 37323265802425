<div class="d-flex justify-content-between"
  [ngClass]="noPadding ? 'p-0' : 'px-3'">
  <div class="py-2">
    <span class="m-0"
      [ngClass]="{'mat-h4': isSmall || isSmall===undefined, 'mat-h2' : !isSmall, 'd-none d-md-block': hideTitleOnMobile, 'font-weight-bold': isStrong }">
      {{title}}
    </span>
  </div>
  <ng-content *ngTemplateOutlet="rightViews"></ng-content>
</div>
<mat-divider *ngIf="hasDivider"></mat-divider>