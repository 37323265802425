import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'prada-title-toolbar',
  templateUrl: './title-toolbar.component.html',
  styleUrls: ['./title-toolbar.component.scss'],
})
export class TitleToolbarComponent implements OnInit {
  @Input()
  title;

  @Input()
  isSmall: boolean;

  @Input()
  noPadding = false;

  @Input()
  hideTitleOnMobile: boolean;

  @Input()
  hasDivider = true;

  @Input()
  isStrong = false;

  @ContentChild('itemDetailsTemplate', { static: true })
  rightViews: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
