import { Directive, Injectable, HostListener, Input } from '@angular/core';

@Injectable()
// tslint:disable-next-line:directive-selector
@Directive({ selector: '[bitfSetFocus]' })
export class BitfSetFocusDirective {
  @Input('bitfSetFocus')
  value: string;

  @HostListener('click', ['$event'])
  onClick($event) {
    this.setFocus($event);
  }

  setFocus($event) {
    if (this.value === '' || this.value.toString() === $event.target.value.toString()) {
      setTimeout(() => {
        $event.srcElement.focus();
        $event.srcElement.select();
      }, 30);
    }
  }
}
