<mat-sidenav-container class="h-100">
  <mat-sidenav #sidenav>
    <ng-container #sidenavContent></ng-container>
  </mat-sidenav>
  <mat-sidenav-content class="prada-white-bg">
    <div class="h-100 d-flex">
      <prada-main-menu class="h-100 d-none d-sm-block"></prada-main-menu>
      <div class="flex-grow-1 content-page">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<bitf-loader></bitf-loader>