import * as Models from '@models';
import { BitfFormItemConfig } from '@bitf/core/models/bitf-form-item-config.model';

export const modelsMap = new Map<string, any>([
  ['users', Models.User],
  ['projects', Models.Project],
  ['form-items-config', BitfFormItemConfig],
  ['moments', Models.Moment],
  ['targets-and-results', Models.TargetAndResultGroup],
  ['intros', Models.Intro],
  ['activities-overview', Models.ActivityOverview],
  ['channels', Models.Channel],
  ['activities', Models.Activity],
  ['celebrities-activity', Models.CelebrityActivity],
  ['events-activity', Models.EventActivity],
  ['gifting-vic-activity', Models.GiftingVicActivity],
  ['influencers-activity', Models.InfluencerActivity],
  ['media-planning-activity', Models.MediaPlanningActivity],
  ['popup-store-activity', Models.PopupStoreActivity],
  ['prada-channel-activity', Models.PradaChannelActivity],
  ['press-schedule-activity', Models.PressScheduleActivity],
  ['wholesale-activity', Models.WholesaleActivity],
  ['files', Models.FileModel],
  ['gantt', Models.Gantt],
  ['influencers', Models.Influencer],
  ['events', Models.SocialCalendar],
  ['event-types', Models.EventType],
  ['overviews', Models.Overview],
  ['channels-dashboard', Models.ChannelDashboard],
  ['kpi-dashboard', Models.Kpi],
  ['audience-target', Models.AudienceBudget],
  ['products', Models.Product],
  ['platforms', Models.Platform],
  ['social', Models.Social],
  ['reports', Models.Report],
]);
