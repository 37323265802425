import { Injectable, Injector } from '@angular/core';
import { BITF_CONFIGS } from '@configs';
import { BitfAuth0AuthService } from '@bitf/core/services/auth/auth0/bitf-auth0-auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfAuth0AuthService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  handleRedirect() {
    this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
    // this.router.navigate([this.appSessionService.redirectUrl]);
  }
}
