import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';
import { User } from '@models';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('users', injector, 'https://localhost:3002/', 'mockDbParser');
    super('users', injector);
  }

  getMe(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<User>> {
    console.log('me', requestParams);
    return super.request<User>({
      ...requestParams,
      path: '/me',
      method: 'GET',
    });
  }
}
