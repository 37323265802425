import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Section, FileModel } from '@models';
import { EBitfCloseEventStatus } from '@bitf/enums';
import { IBitfCloseEvent } from '@web/interfaces';

@Component({
  selector: 'prada-file-uploader-dialog',
  templateUrl: './file-uploader-dialog.component.html',
  styleUrls: ['./file-uploader-dialog.component.scss'],
})
export class FileUploaderDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FileUploaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<unknown>);
  }

  onFilesUploaded(files: FileModel | FileModel[]) {
    this.dialogRef.close({
      data: files,
      status: EBitfCloseEventStatus.OK,
    } as IBitfCloseEvent<FileModel[]>);
  }
}
