import { OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounce, filter, distinctUntilChanged } from 'rxjs/operators';
import { timer, Subscription } from 'rxjs';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { ApiCallStateService } from '@services';
import { EApiRequestPartKeys } from '@enums';

export class BitfSearchComponent implements OnInit, OnDestroy {
  @Input()
  apiCallStateName: string;

  @Input()
  apiRequestPartKey = EApiRequestPartKeys.SEARCH;

  @Input()
  formKey: string;

  @Input()
  formKeys: string[];

  @Input()
  hasSearchIcon = true;

  @Input()
  hasCancelButton = false;

  @Input()
  isInToolbar = false;

  @Input()
  size = 'sm'; // "sm" | "xl"

  @Input()
  placeholder = '';

  @Input() minLength = 3;

  @Output()
  valueChanges: EventEmitter<any> = new EventEmitter();

  requestPart: BitfApiRequestPart;
  paginationRequestPart: BitfApiRequestPart;
  formControl: FormControl = new FormControl('');
  subscription: Subscription = new Subscription();

  isFirstSearchDone = false;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    if (!this.formKey && !this.formKeys) {
      throw new Error('formKey and formKeys empty');
    }

    if (this.formKey) {
      this.formKeys = [this.formKey];
    }

    if (this.apiCallStateName) {
      this.requestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        this.apiRequestPartKey
      );

      this.paginationRequestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        EApiRequestPartKeys.PAGINATION
      );

      this.subscription = this.apiCallStateService.getStateStore$(this.apiCallStateName).subscribe(() => {
        this.patchWithApiRequestPart();
      });
      this.patchWithApiRequestPart();
    }

    this.formControl.valueChanges
      .pipe(
        debounce(() => timer(500)),
        distinctUntilChanged(),
        filter(value => value.length >= this.minLength || (this.isFirstSearchDone && value.length === 0))
      )
      .subscribe(value => {
        this.isFirstSearchDone = true;
        this.valueChanges.emit(value);

        if (this.apiCallStateName) {
          this.apiCallStateService.setStore(() => {
            this.requestPart.formValue = {
              ...this.requestPart.formValue,
            };
            this.formKeys.forEach(key => {
              this.requestPart.formValue[key] = value;
            });

            // NOTE: reset pagination
            if (this.paginationRequestPart) {
              this.paginationRequestPart.reset();
            }
          }, this.apiCallStateName);
        }
      });
  }

  clearSearchField() {
    this.formControl.setValue('');
  }

  private patchWithApiRequestPart() {
    const formValue = this.requestPart.formValue;
    if (formValue && formValue[this.formKeys[0]]) {
      this.formControl.patchValue(formValue[this.formKeys[0]]);
    } else {
      this.formControl.patchValue('');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
