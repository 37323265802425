import { Activity } from './activity.model';

export class PradaChannelActivity extends Activity {
  platformId: string;
  formatId: string;
  format: string;
  link: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      ...new Activity(this).serialised,
      platformId: this.platformId,
      formatId: this.formatId,
      format: this.format,
      link: this.link,
    };
  }
}
