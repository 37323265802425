import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IBitfApiRequest, IBitfApiResponse } from '../api/bitf-api.interface';
import { BitfApiService } from '../api/bitf-api.service';
import { BlobStorageRequest, BlobStorageRequestToken } from './types/azure-storage';

@Injectable({
  providedIn: 'root',
})
export class AzureStorageService extends BitfApiService {
  blobStorageRequest: BlobStorageRequestToken;

  constructor(public injector: Injector) {
    super('azure-storage', injector);
  }

  getServiceSasToken(): Observable<IBitfApiResponse<BlobStorageRequestToken>> {
    return super.request({
      method: 'GET',
      relation: '/service',
    } as IBitfApiRequest);
  }

  getBlobSasToken(fileName: string): Observable<IBitfApiResponse<BlobStorageRequestToken>> {
    return super.request({
      method: 'GET',
      path: `/blob/${fileName}`,
    } as IBitfApiRequest);
  }

  // getSasTokenStatic(): Observable<IBitfApiResponse<BlobStorageRequestToken>> {
  //   const fifteenMinutesBefore = new Date();
  //   fifteenMinutesBefore.setMinutes(fifteenMinutesBefore.getMinutes() - 15);

  //   let obs$: Observable<IBitfApiResponse<BlobStorageRequestToken>>;

  //   if (!this.blobStorageRequest || this.blobStorageRequest.dateExpiration >= fifteenMinutesBefore) {
  //     obs$ = this.getServiceSasToken();
  //   } else {
  //     let blobWrapped: IBitfApiResponse<BlobStorageRequestToken>;
  //     blobWrapped.content = this.blobStorageRequest;
  //     obs$ = of(blobWrapped);
  //   }
  //   return obs$;
  // }
}
