import { Injectable, Injector } from '@angular/core';

// tslint:disable-next-line:max-line-length
import { BitfWebUiMessagesListenerService } from '@bitf/services/ui-messages-listener/web/bitf-web-ui-messages-listener.service';

import { IBitfUiMessages } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class UiMessagesListenerService extends BitfWebUiMessagesListenerService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  displayFullScreenMessage(uiMessage: IBitfUiMessages) {
    const dialogSize = this.appSessionService.activeBreakpoints.isDesktop ? 'auto' : '100%';
    super.displayFullScreenMessage(uiMessage, {
      width: dialogSize,
      maxWidth: dialogSize,
      height: dialogSize,
      maxHeight: dialogSize,
    });
  }
}
