import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfFormItemConfig } from '@common/libs/bitforce/core/models';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';
import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class InfluencersService extends BitfApiService {
  constructor(public injector: Injector) {
    super('influencers', injector);
    // super('influencers', injector, environment.mockApiUrl, 'mockDbParser');
  }

  getFormItemConfig(influencerId: any): Observable<IBitfApiResponse<BitfFormItemConfig>> {
    return super.request({
      method: 'GET',
      id: influencerId,
      relation: 'form-items-config',
    } as IBitfApiRequest);
  }
}
