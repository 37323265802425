import { Injectable } from '@angular/core';

import { BitfStoreService } from '@bitf/core/services/store/bitf-store.service';

import { Store } from '@models';

@Injectable({
  providedIn: 'root',
})
// NOTE: if you use storeStorage, the initalData must defined in the storeStorage, otherwise here
export class StoreService extends BitfStoreService<Store> {
  constructor() {
    super({ storeClass: Store });
  }
}
