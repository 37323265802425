import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { ApiCallStateService } from '@services';
import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IBitfApiPagination, IBitfPaginatorOptions } from '@interfaces';

export class BitfPaginatorComponent implements OnInit {
  @Input()
  apiCallStateName: EApiCallStateNames;

  @Input()
  options: IBitfPaginatorOptions;
  @Input()
  pageSizeOptions = [1, 5, 15];
  // NOTE: mat paginator first page starts with 0 index
  @Input()
  pageIndexOffset = 0;

  _pagination: IBitfApiPagination;
  @Input('pagination')
  set pagination(value: IBitfApiPagination) {
    this._pagination = value;
    this.updatePaginator();
  }
  get pagination(): IBitfApiPagination {
    return this._pagination;
  }

  @Output()
  pageChange: EventEmitter<IBitfApiPagination> = new EventEmitter();

  requestPart: BitfApiRequestPart;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    if (this.apiCallStateName) {
      this.requestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        EApiRequestPartKeys.PAGINATION
      );
    }
  }

  onPageChange(pagination: IBitfApiPagination) {
    if (this.apiCallStateName) {
      this.apiCallStateService.setStore(() => {
        this.requestPart.data = {
          page: pagination.page,
          size: pagination.size,
        };
      }, this.apiCallStateName);
    }

    this.pageChange.emit(pagination);
  }

  updatePaginator() {}
}
