import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class ChannelGroupsService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('channel-groups', injector, 'https://localhost:3002/', 'mockDbParser');
    super('channel-groups', injector);
  }
}
