<mat-list role="list"
  class="mobile-menu">
  <ng-template ngFor
    let-channelGroup
    [ngForOf]="storeService.store.channelGroups">
    <div class="d-flex justify-content-between align-items-center"
      (click)="gotoChannelGroup(channelGroup)">
      <mat-list-item role="listitem">{{channelGroup.label}}</mat-list-item>
      <mat-icon class="mr-1">keyboard_arrow_right</mat-icon>
    </div>

    <mat-divider></mat-divider>
  </ng-template>
</mat-list>