import { EBitfStoreActions } from '@bitf/core/services/store/bitf-store.enum';

export enum EProjectsStatus {
  TO_CHECK = 0,
  CHECKED = 1,
  REVIEW = 2,
  NEW = 3,
}

export enum EProjectsMoments {
  SHOW = 'fashion-show',
  PRE_ORDER = 'pre-order',
  DROP = 'drop-in-store',
}

export enum EProjectsRoutes {
  ACTIVITIES = 'activities',
  GANTT = 'gantt',
  DASHBOARD = 'dashboard',
  OVERVIEW = 'overview',
  INTRO = 'intro',
  TARGETS_AND_RESULTS = 'targets-and-results',
  AUDIENCE_AND_BUDGET = 'audience-and-budget',
  BUDGET = 'budget',
  KPI = 'kpi-dashboard',
  PRADA_CHANNELS = 'prada-channels',
  PRESS_SCHEDULE = 'press-schedule',
  MEDIA_PLANNING = 'media-planning',
  CELEBRITIES = 'celebrities',
  INFLUENCERS = 'influencers',
  GIFTING_VIC = 'gifting-vic',
  EVENTS = 'events',
  WHOLESALE = 'wholesale',
  POPUP_STORE = 'popup-store',
  CHANNELS = 'channels',
  REPORTS = 'reports',
}

export enum EFormMode {
  CREATE = 'create',
  UPDATE = 'update',
  FILTER = 'filter',
}

export enum EFileActionType {
  DELETE = 'delete',
  DOWNLOAD = 'download',
  SHARE = 'share',
}

export enum EUserActions {
  ADD_ACTIVITY = 'add_activity',
}

export enum EPradaStoreActions {
  CHANNEL_GROUPS = 'CHANNEL_GROUPS',
  CHANNEL_GROUP_SLUG = 'CHANNEL_GROUP_SLUG',
  MOMENTS = 'MOMENTS',
  SET_BRAND = 'SET_BRAND',
}

export const eStoreActions = { ...EBitfStoreActions, ...EPradaStoreActions };
