import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StoreService, AuthService, StorageService } from '@services';
import { Brand } from '@web/core/models';
import { BITF_CONFIGS } from '@web/configs';
import { eStoreActions } from '@web/enums';

@Component({
  selector: 'prada-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
})
export class HeaderLogoComponent implements OnInit {
  constructor(
    private storeService: StoreService,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService
  ) {}

  brandImageUrl;
  brands: Brand[] = [];

  ngOnInit() {
    this.brandImageUrl = 'assets/images/brands/' + this.getImageName(this.storeService.store.brand.slug);
    this.brands = this.storeService.store.user.formItemsConfig.brands.options.map(
      value =>
        new Brand({
          id: value.id,
          label: value.label,
          slug: value.metaData.slug,
        })
    );
  }

  getImageName(brand: string): string {
    switch (brand) {
      case 'car-shoe':
      case 'churchs':
      case 'prada':
      case 'miu-miu':
        return brand + '.png';
      default:
        return brand + '.svg';
    }
  }

  choseBrand(selectedBrand: Brand) {
    this.storageService.data = {
      selectedBrand,
    };
    this.storeService.store.brand = selectedBrand;
    this.storeService.setStore(store => {
      store.brand = selectedBrand;
    }, eStoreActions.SET_BRAND);

    this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
    location.replace('/');
  }
}
