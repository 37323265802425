<div class="vertical-menu d-flex flex-column align-items-center">
  <div class="flex-grow-1">
    <mat-list>
      <mat-list-item>
        <button mat-icon-button
          (click)="openLeftMenu()">
          <mat-icon>menu</mat-icon>
        </button>
      </mat-list-item>

      <mat-list-item *ngFor="let item of menuItems"
        [ngClass]="{ 'd-none d-sm-block': item.url === '/communication-plan' }">
        <button mat-icon-button
          [routerLink]="item.url"
          matTooltip="{{item.label | translate}}">
          <mat-icon matListIcon>{{ item.icon }}</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </div>

  <div>

  </div>
</div>