<mat-toolbar class="align-items-center prada-white-bg pl-0 pr-3">
  <div class="d-flex align-items-center">
    <div class="d-block d-sm-none">
      <button mat-icon-button
        (click)="openMainMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <prada-header-logo class="col-auto p-0 "></prada-header-logo>
  </div>

  <div class="w-100 align-items-center">
    <ng-content></ng-content>
  </div>
</mat-toolbar>