import { Activity } from './activity.model';
import { Influencer } from '@models';

export class InfluencerActivity extends Activity {
  influencerId: number;
  influencer: Influencer;
  format: string;

  constructor(data: any = {}) {
    super(data);

    if (data.influencer) {
      this.influencer = new Influencer(data.influencer);
    }
  }

  get serialised() {
    return {
      ...new Activity(this).serialised,
      influencerId: this.influencerId,
      format: this.format,
    };
  }
}
