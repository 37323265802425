import { Component, OnInit, Injectable, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';

import { CountryGroup } from '@models';

/**
 * Node for to-do item
 */
export class BitfItemNode {
  children: BitfItemNode[];
  label: string;
  value: any;
  hidden = false;
}

/** Flat to-do item node with expandable and level information */
export class BitfItemFlatNode {
  level: number;
  expandable: boolean;
  label: string;
  value: any;
  hidden = false;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class FormTreeDatabase {
  dataChange = new BehaviorSubject<BitfItemNode[]>([]);

  get data(): BitfItemNode[] {
    return this.dataChange.value;
  }

  constructor() {}

  treeData: any;
  treeDataLabelKey: string;
  treeDataIdKey: string;

  initialize(treeData, treeDataLabelKey: string, treeDataIdKey: string) {
    this.treeData = treeData;
    this.treeDataLabelKey = treeDataLabelKey;
    this.treeDataIdKey = treeDataIdKey;

    // Build the tree nodes from Json object. The result is a list of `BitfItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(treeData, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `BitfItemNode`.
   */
  buildFileTree(obj: [{ [key: string]: any }], level: number): BitfItemNode[] {
    return Object.keys(obj).reduce<BitfItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new BitfItemNode();

      node.label = value[this.treeDataLabelKey];
      node.value = value[this.treeDataIdKey];
      node.hidden = value['hidden'];
      if (value.options !== undefined) {
        node.children = this.buildFileTree(value.options, level + 1);
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'prada-form-tree-select',
  templateUrl: './form-tree-select.component.html',
  styleUrls: ['./form-tree-select.component.scss'],
  providers: [FormTreeDatabase],
})
export class FormTreeSelectComponent implements OnInit, OnChanges {
  /**
   * Input type => [Object]
   * Object must have a "options" key for create child => options: [Object]
   */
  @Input()
  treeData: any;

  /** Array of "value" node => [{value}, {value}] */
  @Input()
  treeDataSelected: any;

  @Input()
  countryGroups: CountryGroup[];

  /** Key for visible label of node */
  @Input()
  treeDataLabelKey: any;

  /** Key for ID of node */
  @Input()
  treeDataIdKey: any;

  /** Enable caseSensitive on search */
  @Input()
  isCaseSensitive: boolean;

  /** Title component */
  @Input()
  fieldName: any;

  /** Search input placeholder */
  @Input()
  searchPlaceholder: any;

  /** Enabled search input */
  @Input()
  searchEnabled: boolean;

  /** Enabled search input */
  @Input()
  expandable = false;

  /** Event for selection */
  @Output()
  selectionChange: EventEmitter<any> = new EventEmitter();

  // Expandable status
  expanded = false;

  /** Input search field */
  searchField: FormControl;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<BitfItemFlatNode, BitfItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<BitfItemNode, BitfItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: BitfItemFlatNode | null = null;

  treeControl: FlatTreeControl<BitfItemFlatNode>;

  treeFlattener: MatTreeFlattener<BitfItemNode, BitfItemFlatNode>;

  dataSource: MatTreeFlatDataSource<BitfItemNode, BitfItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<BitfItemFlatNode>(true /* multiple */);

  constructor(private database: FormTreeDatabase) {}

  ngOnInit() {
    this.database.initialize(this.treeData, this.treeDataLabelKey, this.treeDataIdKey);
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<BitfItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });

    // Subscribe search
    this.searchField = new FormControl();
    this.searchField.valueChanges.subscribe(term => {
      this.filter(term);
      if (term) {
        this.treeControl.expandAll();
      } else {
        this.treeControl.collapseAll();
      }
    });

    if (!this.expandable) {
      this.expanded = true;
    }

    // Set default selected node
    this.setSelected(this.treeDataSelected);
  }

  ngOnChanges(changes) {
    if (changes.treeDataSelected) {
      this.setSelected(changes.treeDataSelected.currentValue);
    }
  }

  setSelected(treeDataSelected: any) {
    if (treeDataSelected && this.treeControl) {
      this.treeControl.dataNodes.map(node => this.checklistSelection.deselect(node));
      treeDataSelected.forEach(element => {
        const node = this.treeControl.dataNodes.find(_node => _node.value === element);
        this.leafSelectionToggle(node);
      });
    }
  }

  /**
   * Use when search node for save default values
   */
  copyNodes(obj) {
    return Object.assign({}, obj);
  }

  /**
   * Filter TreeData
   */
  filter(filterText: string) {
    const searchKey = this.treeDataLabelKey;
    const isCaseSensitive = this.isCaseSensitive;
    if (filterText) {
      this.treeControl.dataNodes.map(node => {
        if (!isCaseSensitive) {
          node.hidden = !node[searchKey].toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
        } else {
          node.hidden = !node[searchKey].includes(filterText);
        }
      });
    } else {
      this.treeControl.dataNodes.map(node => {
        node.hidden = false;
      });
    }
  }

  getLevel = (node: BitfItemFlatNode) => node.level;

  isExpandable = (node: BitfItemFlatNode) => node.expandable;

  getChildren = (node: BitfItemNode): BitfItemNode[] => node.children;

  hasChild = (_: number, _nodeData: BitfItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: BitfItemFlatNode) => _nodeData.label === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: BitfItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.label === node.label ? existingNode : new BitfItemFlatNode();
    flatNode.label = node.label;
    flatNode.level = level;
    flatNode.expandable = node.children !== undefined ? node.children.length > 0 : false;
    flatNode.value = node.value;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
    // tslint:disable-next-line: semicolon
  };

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  parentSelectionToggle(node: BitfItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.emitChange();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  leafSelectionToggle(node: BitfItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.emitChange();
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: BitfItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child => this.checklistSelection.isSelected(child));
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: BitfItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Whether part of the descendants are selected */
  descendantsHasVisbileNode(node: BitfItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.filter(child => !child.hidden);
    return result.length > 0;
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  private checkAllParentsSelection(node: BitfItemFlatNode): void {
    let parent: BitfItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  private checkRootNodeSelection(node: BitfItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  private getParentNode(node: BitfItemFlatNode): BitfItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Event of selection */
  private emitChange() {
    const selected = this.treeControl.dataNodes.filter(node => this.checklistSelection.isSelected(node));

    this.onSelectionChange({ values: selected });
  }

  private onSelectionChange(event) {
    this.selectionChange.emit(event);
  }
}
