import { Injectable, Injector } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';

import { BitfMetadataService } from '@bitf/services/metadata/bitf-metadata.service';
import { BitfDynamicLocaleService } from '@bitf/services/locale/bitf-dynamic-locale.service';
import { environment } from '@env/environment';
import { APP_VERSION } from '@env/version';

import {
  UiMessagesListenerService,
  ApiCallStateService,
  StorageService,
  StoreService,
  LoaderService,
} from '@services';

@Injectable({
  providedIn: 'root',
})
export abstract class BitfAppSessionService {
  protected translate: TranslateService;
  protected bitfMetadataService: BitfMetadataService;
  protected loaderService: LoaderService;
  protected uiMessagesListenerService: UiMessagesListenerService;
  protected apiCallStateService: ApiCallStateService;
  protected storageService: StorageService;
  protected storeService: StoreService;
  protected bitfDynamicLocaleService: BitfDynamicLocaleService;
  protected router: Router;
  private loaderShownForRoute = new Map<string, boolean>();

  constructor(protected injector: Injector) {}

  init() {
    this.translate = this.injector.get(TranslateService);
    this.storeService = this.injector.get(StoreService);
    this.loaderService = this.injector.get(LoaderService);
    this.router = this.injector.get(Router);

    /* NOTE:
    The StorageService initialize in the constructor because access is gained from dynamic local service
     */
    this.storageService = this.injector.get(StorageService);

    this.bitfDynamicLocaleService = this.injector.get(BitfDynamicLocaleService);
    this.bitfDynamicLocaleService.init();

    this.bitfMetadataService = this.injector.get(BitfMetadataService);
    this.bitfMetadataService.init();

    this.uiMessagesListenerService = this.injector.get(UiMessagesListenerService);
    this.uiMessagesListenerService.init();

    this.apiCallStateService = this.injector.get(ApiCallStateService);
    this.apiCallStateService.init();

    this.initMobileFixes();

    this.initLogSender();

    this.initShowLoaderOnRouteChange();

    if (!environment.production) {
      console.log('ENV', environment);
    }
    window['printEnv'] = () => {
      console.table('Environment: ', environment);
      console.log('App Version: ', APP_VERSION);
    };
  }

  initMobileFixes() {
    const setVhValue = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVhValue();

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        tap(() => setVhValue())
      )
      .subscribe();
  }

  initShowLoaderOnRouteChange() {
    this.router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof RouteConfigLoadStart) {
        if (!this.loaderShownForRoute.has(event.route.path)) {
          this.loaderShownForRoute.set(event.route.path, true);
          this.loaderService.show();
        }
      } else if (event instanceof RouteConfigLoadEnd) {
        if (this.loaderShownForRoute.has(event.route.path)) {
          this.loaderShownForRoute.delete(event.route.path);
        }
        this.loaderService.hide();
      }
    });
  }

  abstract initLogSender();
}
