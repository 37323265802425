import { Observable } from 'rxjs/Observable';

import { IBitfFileValidationRule, IBitfImageSizeValidation } from '@interfaces';

import { BitfFile } from '@bitf/core/models/bitf-file.model';
import { bitfBytesPipeFn } from '@bitf/pipes/bitf-bytes.function';
import { TranslateService } from '@ngx-translate/core';
import { bitfToTranslate } from './bitf-translate.utils';
import { BlobDownloadResponseParsed } from '@azure/storage-blob';

// TODO update this to update BitfFile.model props
// export function bitfImageSizeValidator(
//   image: Blob | File,
//   imageSizeRules: IBitfImageSizeValidation
// ): Observable<IBitfFileValidationResult> {
//   const obs = Observable.create(observer => {
//     const img = new Image();
//     img.onload = () => {
//       let result;
//       if (imageSizeRules.height < img.height || imageSizeRules.width < img.width) {
//         result = {
//           isValid: false,
//           errors: [
//           `Image height and width must not exceed ${imageSizeRules.height}px x ${imageSizeRules.width}px.`,
//           ],
//         } as IBitfFileValidationResult;
//       } else {
//         result = { isValid: true, errors: [] } as IBitfFileValidationResult;
//       }
//       observer.next(result);
//     };
//     img.src = URL.createObjectURL(image);
//   });
//   return obs;
// }

export function bitfValidateFiles(
  filesToValidate: BitfFile | BitfFile[],
  validationRule: IBitfFileValidationRule,
  translateService: TranslateService
): BitfFile | BitfFile[] {
  let files;
  let isArray = true;
  if (!Array.isArray(filesToValidate)) {
    files = [filesToValidate];
    isArray = false;
  } else {
    files = filesToValidate;
  }

  // clear previous state of validations
  files.forEach((f: BitfFile) => (f.validationErrors = []));

  if (Array.isArray(validationRule.extensions) && validationRule.extensions.length) {
    files.forEach((f: BitfFile) => {
      const fileExt = f.fileObject.name.split('.').pop();
      const isExtAllowed = validationRule.extensions.filter(ext =>
        f.fileObject.name.toUpperCase().endsWith(ext.toUpperCase())
      );

      if (!isExtAllowed.length) {
        bitfToTranslate('BITF.FILE_UPLOADER.VALIDATION_EXTENSION_ERROR');
        const error = translateService.instant('BITF.FILE_UPLOADER.VALIDATION_EXTENSION_ERROR', {
          value: fileExt,
        });
        f.validationErrors.push(error);
        f.isValid = false;
      }
    });
  }

  if (validationRule.maxFileSize) {
    files.forEach((f: BitfFile) => {
      if (validationRule.maxFileSize < f.fileObject.size) {
        bitfToTranslate('BITF.FILE_UPLOADER.VALIDATION_FILE_SIZE_ERROR');
        const error = translateService.instant('BITF.FILE_UPLOADER.VALIDATION_FILE_SIZE_ERROR', {
          value: bitfBytesPipeFn(f.fileObject.size, 0),
          max: bitfBytesPipeFn(validationRule.maxFileSize, 0),
        });
        f.validationErrors.push(error);
        f.isValid = false;
      }
    });
  }

  if (!isArray) {
    return files[0];
  }
  return files;
}

export const bitfGenerateFilesList = (filesList: FileList): BitfFile[] => {
  if (!filesList) {
    return [];
  }
  const arrayOfFiles = Array.from(filesList);
  return arrayOfFiles.map(file => new BitfFile(file));
};

export const bitfPromptToDownloadFile = (
  fileContent: ArrayBuffer,
  fileType: string,
  fileExtension: string,
  fileName?: string
) => {
  const downloadFile = new Blob([fileContent], { type: fileType });
  const objectUrl = URL.createObjectURL(downloadFile);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const objectUrlParts = objectUrl.split('/');
    window.navigator.msSaveOrOpenBlob(
      downloadFile,
      fileName ? fileName : `${objectUrlParts[objectUrlParts.length - 1]}.${fileExtension}`
    );
  } else {
    const a = document.createElement('a');
    a.href = objectUrl;
    if (fileName && fileExtension) {
      a.download = `${fileName}.${fileExtension}`;
    } else if (fileName) {
      a.download = `${fileName}`;
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const azurePromptToDownloadFile = (
  blobToDownload: BlobDownloadResponseParsed,
  fileType: string,
  fileExtension: string,
  fileName?: string
) => {
  blobToDownload.blobBody.then(downloadFile => {
    const objectUrl = URL.createObjectURL(downloadFile);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const objectUrlParts = objectUrl.split('/');
      window.navigator.msSaveOrOpenBlob(
        downloadFile,
        fileName ? fileName : `${objectUrlParts[objectUrlParts.length - 1]}.${fileExtension}`
      );
    } else {
      const a = document.createElement('a');
      a.href = objectUrl;
      if (fileName) {
        a.download = `${fileName}`;
      } else {
        a.download = `file.${fileExtension}`;
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  });
};
