import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class MomentsService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('moments', injector, 'https://localhost:3002/', 'mockDbParser');
    super('moments', injector);
  }
}
