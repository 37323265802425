import { Inject, Injectable, Injector } from '@angular/core';
import { BlobDeleteIfExistsResponse, BlobDownloadResponseParsed, BlockBlobClient } from '@azure/storage-blob';
import { FileModel } from '@web/core/models';
import { StoreService } from '@web/core/services';
import { from, Observable, OperatorFunction, Subscriber } from 'rxjs';
import { distinctUntilChanged, filter, map, scan, startWith, switchMap, tap } from 'rxjs/operators';
import { BitfFile } from '../../models';
import { BitfApiHelper } from '../api/bitf-api.helper';
import { IBitfApiRequest, IBitfApiResponse } from '../api/bitf-api.interface';
import { BitfApiService } from '../api/bitf-api.service';
import { BlobStorageService } from './blob-storage.service';
import { AzureStorageService } from './azure-storage.service';
import { BlobFileRequest, BlobStorageRequest } from './types/azure-storage';

@Injectable({
  providedIn: 'root',
})
export class BlobSharedService extends BitfApiService {
  constructor(
    public injector: Injector,
    private azureStorageService: AzureStorageService,
    private blobStorageService: BlobStorageService,
    private storeService: StoreService
  ) {
    super('azure', injector);
  }

  public getStorageOptions(): Observable<IBitfApiResponse<BlobStorageRequest>> {
    return this.azureStorageService.getServiceSasToken();
  }

  downloadAzure(file: FileModel): Observable<BlobDownloadResponseParsed> {
    return this.azureStorageService.getServiceSasToken().pipe(
      // return this.azureStorageService.getBlobSasToken(file.downloadUrl).pipe(
      map((response: IBitfApiResponse<BlobStorageRequest>) => {
        const blobStorageRequest = response.content;

        const blobFileRequest: BlobFileRequest = {
          filename: file.downloadUrl,
          containerName: 'images',
          storageAccessToken: blobStorageRequest.storageAccessToken,
          storageUri: blobStorageRequest.storageUri,
        };
        return blobFileRequest;
      }),
      switchMap(blobFileRequest => {
        return this.blobStorageService.downloadBlobItem(blobFileRequest);
      })
    );
  }

  deleteAzure(file: FileModel): Observable<BlobDeleteIfExistsResponse> {
    return this.azureStorageService.getServiceSasToken().pipe(
      // return this.azureStorageService.getBlobSasToken(file.downloadUrl).pipe(
      map((response: IBitfApiResponse<BlobStorageRequest>) => {
        const blobStorageRequest = response.content;

        const blobFileRequest: BlobFileRequest = {
          filename: file.downloadUrl,
          containerName: 'images',
          storageAccessToken: blobStorageRequest.storageAccessToken,
          storageUri: blobStorageRequest.storageUri,
        };
        return blobFileRequest;
      }),
      switchMap(blobFileRequest => {
        return this.blobStorageService.deleteBlobItem(blobFileRequest);
      })
    );
  }

  uploadAzure(requestParams: IBitfApiRequest = {}): Observable<FileModel> {
    requestParams.file.resetUploadState();

    return this.azureStorageService.getServiceSasToken().pipe(
      map((response: IBitfApiResponse<BlobStorageRequest>) => {
        const blobStorageRequest = response.content;

        if (!requestParams.fileFormFieldName) {
          requestParams.fileFormFieldName = 'file';
        }

        const fileName = requestParams.file.fileObject.name;
        const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
        const name = fileName.substr(0, fileName.lastIndexOf('.'));

        const blobFileRequest: BlobFileRequest = {
          filename: name + new Date().getTime() + '.' + extension,
          containerName: requestParams.relation,
          storageAccessToken: blobStorageRequest.storageAccessToken,
          storageUri: blobStorageRequest.storageUri,
        };
        return blobFileRequest;
      }),
      switchMap(blobFileRequest => {
        return this.blobStorageService
          .uploadToBlobStorage(requestParams.file.fileObject, blobFileRequest)
          .pipe(
            tap(progress => {
              this.mapProgress(requestParams.file, progress);
            }),
            filter(progress => progress === requestParams.file.fileObject.size),
            map(() => {
              return this.mapResponse(requestParams, blobFileRequest);
            })
          );
      })
    );
  }

  private mapProgress(file: BitfFile, progress: number) {
    file.isUploading = true;
    file.uploadedPercentage = parseInt(((progress / file.fileObject.size) * 100).toString(), 10);

    if (file.uploadedPercentage >= 98) {
      file.isProcessing = true;
    }
    if (file.uploadedPercentage === 100) {
      file.isProcessing = false;
      file.uploadedPercentage = 100;
      file.isUploaded = true;
      file.isUploading = false;
    }
  }

  private mapResponse(requestParams: IBitfApiRequest, blobFileRequest: BlobFileRequest) {
    return new FileModel({
      name: requestParams.file.fileObject.name,
      type: requestParams.file.fileObject.type,
      url: blobFileRequest.filename,
      downloadUrl: blobFileRequest.filename,
      uploadedBy: this.storeService.store.user?.name,
      uploadedAt: new Date(),
    });
  }
}
