export enum EApiCallStateNames {
  DEMO = 'DEMO',
  PROJECTS_LIST = 'PROJECTS_LIST',
  PROJECT_GANTT = 'PROJECT_GANTT',
  INFLUENCER_LIST = 'INFLUENCER_LIST',
  ACTIVITIES = 'ACTIVITIES',
  COMMUNICATION_PLAN = 'COMMUNICATION_PLAN',
  SOCIAL_CALENDAR_LIST = 'SOCIAL_CALENDAR_LIST',
}
export enum EApiRequestPartKeys {
  PAGINATION = 'PAGINATION',
  FILTERS = 'FILTERS',
  FILTER_YEAR = 'FILTER_YEAR',
  PROJECTS_LIST_ARCHIVED = 'PROJECTS_LIST_ARCHIVED',
  SEARCH = 'SEARCH',
  SORTING = 'SORTING',
  SORT_EVENT_LIST_NAME = 'SORT_EVENT_LIST_NAME',
  SORT_EVENT_LIST_TYPE = 'SORT_EVENT_LIST_TYPE',
  SORT_EVENT_LIST_START_DATE = 'SORT_EVENT_LIST_START_DATE',
  SORT_EVENT_LIST_END_DATE = 'SORT_EVENT_LIST_END_DATE',
  SORT_EVENT_LIST_REPEAT = 'SORT_EVENT_LIST_REPEAT',
  SORT_EVENT_LIST_RECURRENT = 'SORT_EVENT_LIST_RECURRENT',
}
