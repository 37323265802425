import { SuperModel } from './super-model.model';

import { BitfFormItemConfig } from '@bitf/core/models/bitf-form-item-config.model';

import { ERoles } from '@enums';

export class User extends SuperModel {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  formItemsConfig: BitfFormItemConfig;
  roles: ERoles[] = [];

  constructor(data: any = {}) {
    super(data);

    if (data.formItemsConfig) {
      this.formItemsConfig = new BitfFormItemConfig(data.formItemsConfig);
    }
    if (this.roles) {
      this.roles = this.mapRoles(data.roles);
    }
  }

  get serialised() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      name: this.name,
      email: this.email,
    };
  }

  get canEditProject(): boolean {
    return this.roles.includes(ERoles.MASTER) || this.roles.includes(ERoles.IT_ADMIN);
  }

  get canArchiveProject(): boolean {
    return this.roles.includes(ERoles.MASTER) || this.roles.includes(ERoles.IT_ADMIN);
  }

  get canPublishProject(): boolean {
    return this.roles.includes(ERoles.IT_ADMIN);
  }

  get canEditIntro(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.USER) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canEditActivity(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.USER) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canEditActivityStatus(): boolean {
    return this.roles.includes(ERoles.MASTER) || this.roles.includes(ERoles.IT_ADMIN);
  }

  get canEditTargetAndResults(): boolean {
    return this.roles.includes(ERoles.MASTER) || this.roles.includes(ERoles.IT_ADMIN);
  }

  get canEditFile(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.USER) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canEditMoment(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.USER) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canViewSettings(): boolean {
    return !this.roles.includes(ERoles.READER);
  }

  get canEditInfluencersSettings(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.USER) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canEditPlatformsSettings(): boolean {
    return this.roles.includes(ERoles.MASTER) || this.roles.includes(ERoles.IT_ADMIN);
  }

  get canEditSocialCalendarSettings(): boolean {
    return this.roles.includes(ERoles.SECRETARY) || this.roles.includes(ERoles.IT_ADMIN);
  }

  get canEditDashboard(): boolean {
    return this.roles.includes(ERoles.MASTER) || this.roles.includes(ERoles.IT_ADMIN);
  }

  get canDownloadPDFDashboard(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.SECRETARY) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canDownloadPDFProject(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.USER) ||
      this.roles.includes(ERoles.SECRETARY) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canDownloadExcelCommunicationPlan(): boolean {
    return (
      this.roles.includes(ERoles.MASTER) ||
      this.roles.includes(ERoles.USER) ||
      this.roles.includes(ERoles.SECRETARY) ||
      this.roles.includes(ERoles.IT_ADMIN)
    );
  }

  get canViewLog(): boolean {
    return this.roles.includes(ERoles.IT_ADMIN);
  }

  private mapRoles(roles: string[]): ERoles[] {
    return roles
      .map((element: string) => element.replace(' ', '_').toUpperCase() as ERoles)
      .filter((eRole: ERoles) => ERoles[eRole]);
  }
}
