import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ChangeDetectionStrategy,
  AfterContentInit,
} from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';

// FIXME REMOVE ALL THESE REFERENCES
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { scaleFormatter } from '@bitf/utils/bitf-numbers.utils';

import { CONSTANTS } from '@constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prada-half-pie-chart',
  templateUrl: './half-pie-chart.component.html',
  styleUrls: ['./half-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HalfPieChartComponent implements OnInit, AfterContentInit {
  @Input()
  target: number;

  @Input()
  real: number;

  @Input()
  decimals = 1;

  @Input() unitOfMeasure: string;

  halfPieChartData: GoogleChartInterface;
  width: number;

  constructor(private el: ElementRef, private translateService: TranslateService) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.width = this.el.nativeElement.offsetWidth;

    this.loadChart();

    fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.width = this.el.nativeElement.offsetWidth;
        if (this.halfPieChartData && this.halfPieChartData.component) {
          Object.assign(this.halfPieChartData.options, {
            width: this.width,
            height: this.width,
          });
          this.halfPieChartData.component.draw();
        }
      });
  }

  get realLabel() {
    return this.formatter(this.real);
  }

  get targetLabel() {
    return this.formatter(this.target);
  }

  get endScaleLabel() {
    return this.formatter(this.target * 2);
  }

  loadChart() {
    if (!this.target && !this.real && this.target > this.real) {
      return;
    }
    this.halfPieChartData = {
      chartType: 'PieChart',
      dataTable: [
        [
          this.translateService.instant('COMMON.LABEL.PROPERTY'),
          this.translateService.instant('COMMON.LABEL.PERCENTAGE'),
        ],
        ['', this.getRealValue()],
        ['', this.getTargetValue()],
        ['', 50],
      ],
      options: {
        width: this.width,
        height: this.width,
        pieSliceText: 'none',
        pieStartAngle: 270,
        pieHole: 0.9,
        tooltip: { trigger: 'none' },
        slices: {
          0: { color: CONSTANTS.lightGreen },
          1: { color: CONSTANTS.lightGrey },
          2: { color: 'transparent' },
        },
        chartArea: { width: '100%', height: '100%' },
        legend: 'none',
        enableInteractivity: false,
      },
    };
  }

  private formatter(value: number): string {
    if (this.unitOfMeasure) {
      return `${value.toFixed(this.decimals)}  ${this.unitOfMeasure}`;
    } else {
      return scaleFormatter(value);
    }
  }

  private getTargetValue(): number {
    if (this.getRealValue() > 0 && this.getRealValue() <= 50) {
      return 50 - this.getRealValue();
    } else if (this.getRealValue() === 0 || !this.getRealValue()) {
      return 50;
    } else {
      return 0;
    }
  }

  private getRealValue(): number {
    const real = (this.real / this.target) * 25;
    return real >= 50 ? 50 : real;
  }
}
