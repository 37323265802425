import { SuperModel } from './super-model.model';

export interface OverviewSales {
  target: number;
  real: number;
  unitOfMeasure: string;
}

export interface OverviewTargetAudience {
  age: string;
  isMan: boolean;
  isWoman: boolean;
  awareness: number;
  conversion: number;
  unitOfMeasure: string;
}

export class Overview extends SuperModel {
  countries: string[];
  hashtag: string;
  launchDate: string;
  links: string[];
  targetAudience: OverviewTargetAudience;
  sales: OverviewSales;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      countries: this.countries,
      hashtag: this.hashtag,
      launchDate: this.launchDate,
      links: this.links,
      targetAudience: this.targetAudience,
      sales: this.sales,
    };
  }
}
