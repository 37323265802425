export class BitfFile {
  fileObject: File;
  isValid = true;
  isUploading = false;
  isUploaded = false;
  isProcessing = false;
  validationErrors: string[] = [];
  uploadedPercentage = 0;
  uploadError: any;
  hasUploadErrors = false;
  metaData: any = {};
  bodyData: any = {};

  constructor(fileObject: File) {
    this.fileObject = fileObject;
  }

  get extension() {
    return this.fileObject.name.split('.').pop();
  }

  get isImage() {
    return this.extension && this.extension.match(/^jpg$|^jpeg$|^png$|^emf$/i);
  }

  get isPdf() {
    return this.extension && this.extension.match(/^pdf$/i);
  }

  get validationErrorsTooltip() {
    return this.validationErrors.join('.');
  }

  resetUploadState() {
    this.isUploading = false;
    this.isUploaded = false;
    this.isProcessing = false;
    this.uploadedPercentage = 0;
    this.uploadError = undefined;
    this.hasUploadErrors = false;
  }
}
