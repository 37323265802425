import { Component, OnInit, Injector, TemplateRef, ContentChild } from '@angular/core';

import { SuperHeaderComponent } from '@web/shared/components/super-header.component';
@Component({
  selector: 'prada-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends SuperHeaderComponent {
  constructor(public injector: Injector) {
    super(injector);
  }
}
