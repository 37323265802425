import { SuperModel } from './super-model.model';
import { Channel } from './channel.model';

export class ChannelGroup extends SuperModel {
  name: string;
  slug: string;
  label: string;
  totalActivities: number;
  channels: Channel[];

  constructor(data: any = {}) {
    super(data);

    if (this.channels) {
      this.channels = this.channels.map(channel => new Channel(channel));
    }
  }
  get serialised() {
    return {
      id: this.id,
      slug: this.slug,
      label: this.label,
      totalActivities: this.totalActivities,
    };
  }
}
