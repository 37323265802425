import { Component } from '@angular/core';

import { CommonLayout } from '@common/core/components/layout/common-layout.component';

@Component({
  selector: 'prada-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
})
export class DesktopLayoutComponent extends CommonLayout {
  updateLayout() {
    const data = this.route.snapshot.firstChild.data;
    this.showHeader = data.showHeader;
  }
}
