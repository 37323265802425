import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormBuilder } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';

import { BitfSetFocusDirective } from '@bitf/directives/bitf-set-focus.directive';
import { BitfUiRoleManagerDirective } from '@bitf/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfFormBuilderService } from '@common/libs/bitforce/core/form';
// tslint:disable-next-line:max-line-length
import { BitfFormControlTranslatePipe } from '@common/libs/bitforce/pipes/translate/bitf-form-control-translate.pipe';

const SHARED_MODULES = [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, TextMaskModule];
const SHARED_COMPONENTS = [];
const SHARED_DIRECTIVES = [BitfSetFocusDirective, BitfUiRoleManagerDirective];
const SHARED_BITF_COMPONENTS = [];
const SHARED_PIPES = [BitfFormControlTranslatePipe];
const PROVIDERS = [[{ provide: FormBuilder, useClass: BitfFormBuilderService }]];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_BITF_COMPONENTS,
    ...SHARED_PIPES,
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_BITF_COMPONENTS, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class CommonSharedModule {
  // This should only be called inside the CoreModule!
}
