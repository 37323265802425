import { Activity } from './activity.model';
import { Influencer } from '@models';

export class GiftingVicActivity extends Activity {
  title: number;
  format: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      ...new Activity(this).serialised,
      title: this.title,
      format: this.format,
    };
  }
}
