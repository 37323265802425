import { OnInit, Injector } from '@angular/core';

import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';
import { EBitfSidenavMode, EBitfSidenavPosition } from '@bitf/enums';

import { SidenavMenuComponent } from '@web/core/components/sidenav-menu/sidenav-menu.component';

export abstract class SuperHeaderComponent implements OnInit {
  constructor(public injector: Injector) {}

  ngOnInit() {}

  openMainMenu() {
    this.injector.get(BitfMatSidenavService).open({
      component: SidenavMenuComponent,
      sidenavOptions: { mode: EBitfSidenavMode.OVER, position: EBitfSidenavPosition.START },
    });
  }
}
