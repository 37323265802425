import { Injectable, Injector } from '@angular/core';

import { IBitfApiRequest } from '@web/interfaces';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';
import { EBitfInterceptors } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
export class CommunicationPlansService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('communication-plans', injector, 'https://localhost:3002/', 'mockDbParser');
    super('communication-plans', injector);
  }

  download(apiRequest: IBitfApiRequest) {
    return super.fetch({
      ...apiRequest,
      method: 'GET',
      responseType: 'arraybuffer',
      relation: 'download',
      headers: [{ headerName: EBitfInterceptors.BITF_RETRY_INTERCEPTOR, value: 'skip' }],
    });
  }
}
