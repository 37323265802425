import { SuperModel } from './super-model.model';
import { FileModel, Product, CountryGroup } from '@models';
import {
  PradaChannelActivity,
  CelebrityActivity,
  EventActivity,
  GiftingVicActivity,
  InfluencerActivity,
  MediaPlanningActivity,
  PopupStoreActivity,
  PressScheduleActivity,
  WholesaleActivity,
} from '@models';

export enum EActivityStatus {
  'UNCHEKCED' = 0,
  'APPROVED' = 1,
  'REJECTED' = 1,
}

export interface IKeyViewName {
  top: string[];
  bottom: string[];
}

export class Activity extends SuperModel {
  content: string;
  startDate: Date;
  endDate: Date;
  notes: string;
  files: FileModel[];
  status: EActivityStatus;
  projectId?: number;
  momentId?: number;
  channelGroupId?: number;
  channelId?: number;
  products: Product[];
  countryGroups: CountryGroup[];
  countriesIds: number[];

  constructor(data: any = {}) {
    super(data);

    if (data.files) {
      this.files = data.files.map(item => new FileModel(item));
    } else {
      this.files = [];
    }

    this.products = (data.products || []).map((item: any) => new Product(item));

    // if (data.startDate && !(data.startDate instanceof Date)) {
    //   this.startDate = new Date(data.startDate.replace('Z', ''));
    // }
    // if (data.endDate && !(data.endDate instanceof Date)) {
    //   this.endDate = new Date(data.endDate.replace('Z', ''));
    // }
  }

  get keyView(): IKeyViewName {
    if (this instanceof CelebrityActivity) {
      return { top: ['influencerName'], bottom: ['socialName'] };
    } else if (this instanceof EventActivity) {
      return { top: ['content'], bottom: ['platform'] };
    } else if (this instanceof GiftingVicActivity) {
      return { top: ['title'], bottom: ['content'] };
    } else if (this instanceof InfluencerActivity) {
      return { top: ['influencerName'], bottom: ['socialName'] };
    } else if (this instanceof MediaPlanningActivity) {
      return { top: ['content'], bottom: ['platform'] };
    } else if (this instanceof PopupStoreActivity) {
      return { top: ['content'], bottom: ['platform'] };
    } else if (this instanceof PradaChannelActivity) {
      return { top: ['content'], bottom: ['platform'] };
    } else if (this instanceof PressScheduleActivity) {
      return { top: ['magazine', 'country'], bottom: ['platform'] };
    } else if (this instanceof WholesaleActivity) {
      return { top: ['content'], bottom: ['platform'] };
    }
  }

  keyViewValues(value: string): string {
    return this.keyView[value].map(key => (this[key] ? this[key] : '')).join(', ');
  }

  get serialised() {
    return {
      id: this.id,
      content: this.content,
      startDate: this.startDate,
      endDate: this.endDate,
      // startDate: getIsoDateWithoutTimezone(this.startDate),
      // endDate: getIsoDateWithoutTimezone(this.endDate),
      notes: this.notes,
      status: this.status,
      projectId: this.projectId,
      momentId: this.momentId,
      channelGroupId: this.channelGroupId,
      channelId: this.channelId,
      countriesIds: this.countriesIds,
    };
  }
}
