import { BitfOption } from './bitf-option.model';

import { IBitfFormControlValidator, IBitfUiRoleManagerConfig } from '@interfaces';

export class BitfFormItem {
  label?: string;
  options?: BitfOption[];
  default?: BitfOption | string | Array<string> | boolean | number;
  setDefault = true;
  isVisible?: boolean;
  isEditable?: boolean;
  isDisabled?: boolean;
  isDeleted?: boolean;
  isRequired?: boolean;
  validators?: IBitfFormControlValidator[] = [];
  linkedItem?: string;
  isGrouped?: boolean;
  type?: string;
  uiRole?: IBitfUiRoleManagerConfig;
  numberMask?: any;
  comparator?: any;
  operator?: any;
  metaData?: any;
  fieldDescription?: string;
  placeholder?: string;
  private _cachedAllOptionsIds?: number[];
  private _cachedDefaultOptionsIds?: number[];

  constructor(data = {}) {
    Object.assign(this, data);
    if (this.options) {
      this.options = this.options.map(option => new BitfOption(option));
    }
    if (this.isVisible === undefined) {
      this.isVisible = true;
    }
    if (this.isDisabled === undefined) {
      this.isDisabled = false;
    }
  }

  get selectedOptionsIds() {
    if (this._cachedDefaultOptionsIds) {
      return this._cachedDefaultOptionsIds;
    }
    this._cachedDefaultOptionsIds = this.getDefaultOptionsIds(this.options);
    return this._cachedDefaultOptionsIds;
  }

  private getDefaultOptionsIds(options: BitfOption[]) {
    if (!options) {
      return [];
    }
    return [].concat(
      options
        .map((option: BitfOption) => (option.isDefault ? option.id : null))
        .filter(option => option !== null),
      ...options.map((option: BitfOption) => this.getDefaultOptionsIds(option.options))
    );
  }

  get allOptionsIds() {
    if (this._cachedAllOptionsIds) {
      return this._cachedAllOptionsIds;
    }
    this._cachedAllOptionsIds = this.getAllOptionsIds(this.options);
    return this._cachedAllOptionsIds;
  }

  private getAllOptionsIds(options: BitfOption[]) {
    if (!options) {
      return [];
    }
    return [].concat(
      options.map((option: BitfOption) => option.id),
      ...options.map((option: BitfOption) => this.getAllOptionsIds(option.options))
    );
  }
}
