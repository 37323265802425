import { SuperModel } from './super-model.model';

export class Moment extends SuperModel {
  label: string;
  slug: string;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  get serialised() {
    return {
      id: this.id,
      label: this.label,
      slug: this.slug,
    };
  }
}
