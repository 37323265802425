<table mat-table
  *ngIf="dataSource?.length"
  [dataSource]="dataSource">

  <ng-container matColumnDef="sticky-left"
    sticky>
    <th mat-header-cell
      *matHeaderCellDef></th>
  </ng-container>

  <ng-container matColumnDef="{{col}}"
    *ngFor="let col of allColumns.slice(1)">
    <th mat-header-cell
      *matHeaderCellDef></th>
  </ng-container>

  <!-- months -->
  <ng-container [matColumnDef]="monthColumnKeys[0]"
    sticky>
    <th class="text-right pr-3"
      mat-header-cell
      *matHeaderCellDef>
      <strong>{{'GANTT.MONTHS' | translate}}</strong>
    </th>
  </ng-container>

  <ng-container matColumnDef="{{monthColumnKey}}"
    *ngFor="let monthColumnKey of monthColumnKeys.slice(1); let i = index">
    <th mat-header-cell
      class="text-center month-column border-left"
      [attr.colspan]="monthsMap.get(monthColumnKey)"
      *matHeaderCellDef>
      <strong>{{monthColumnKey}}</strong>
    </th>
  </ng-container>

  <!-- Days -->
  <ng-container [matColumnDef]="dayColumnKeys[0]"
    sticky>
    <th class="text-right pr-3"
      mat-header-cell
      *matHeaderCellDef>
      <strong>{{'GANTT.DAYS' | translate}}</strong>
    </th>
  </ng-container>

  <ng-container matColumnDef="{{dayColumnKey}}"
    *ngFor="let dayColumnKey of dayColumnKeys.slice(1); let i = index">
    <th mat-header-cell
      *matHeaderCellDef
      class="border-left">
      <div class="font-weight-bold text-center">{{days[i].date}}</div>
    </th>
  </ng-container>


  <!-- Social calendar -->
  <!-- <ng-container [matColumnDef]="socialCalendarColumnKeys[0]"
      sticky>
      <th class="text-right pr-3"
        mat-header-cell
        *matHeaderCellDef>social</th>
    </ng-container>

    <ng-container matColumnDef="{{socialCalendarColumnKey}}"
      *ngFor="let socialCalendarColumnKey of socialCalendarColumnKeys.slice(1); let i = index">
      <th mat-header-cell
        *matHeaderCellDef
        class="border-left social-calendar-row">
        <div class="font-weight-bold text-center">{{socialCalendarColumnKey}}asd</div>
      </th>
    </ng-container> -->


  <!-- cards -->
  <ng-container [matColumnDef]="cardColumnKeys[0]"
    sticky>
    <th mat-header-cell
      *matHeaderCellDef></th>

    <td mat-cell
      *matCellDef="let element; let i = index; let first = first"
      [ngClass]="{'isRowTitle': element.isRowTitle, 'isSubRowTitle': element.isSubRowTitle, 'isRowCard': !element.isRowTitle && !element.isSubRowTitle, 'border-list': (element.cardType === 'SOCIAL_CALENDAR' && element.label )}"
      class="{{element.cardType}}">
      <div class="mb-1 mat-caption text-uppercase"
        *ngIf="!element.isRowTitle && !element.isSubRowTitle && element.header">{{element.header}}</div>

      <strong [matTooltip]="element.label">
        <span class="align-items-center"
          [ngClass]="{'px-2 py-1 rounded': (element.cardType === 'SOCIAL_CALENDAR' && element.label ), 'd-flex': element.isRowTitle || element.isSubRowTitle}"
          [ngStyle]="{'background-color': element.color}">
          <mat-icon *ngIf="element.isRowTitle && !element.expanded">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="element.isRowTitle && element.expanded">keyboard_arrow_up</mat-icon>
          {{element.label}}
        </span>
      </strong>
      <div class="mat-caption"
        *ngIf="!element.isRowTitle && !element.isSubRowTitle && element.startDate">
        {{element.startDate | date:'mediumDate'}} - {{element.endDate | date:'mediumDate'}}</div>

      <div class="mt-1"
        *ngIf="!element.isRowTitle && element.note">{{element.note}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="{{cardColumnKey}}"
    *ngFor="let cardColumnKey of cardColumnKeys.slice(1); let i = index">
    <th mat-header-cell
      *matHeaderCellDef>
    </th>

    <td mat-cell
      *matCellDef="let element"
      class="border-left {{element.cardType}}"
      [ngClass]="{'isRowTitle': element.isRowTitle, 'isRowCard': !element.isRowTitle && !element.isSubRowTitle}">
      <div *ngIf="element.cardsMap && element.cardsMap[days[i].isoDate]"
        [ngStyle]="{'width': element.cardsMap[days[i].isoDate].width}"
        class="card"
        (click)="cardClick.emit(element.cardsMap[days[i].isoDate])">

        <mat-card *ngIf="element.cardsMap[days[i].isoDate].cardType === ECardType.ACTIVITY"
          class="w-100 text-truncate p-2 mat-elevation-z3 rounded-0 d-flex"
          [ngStyle]="{backgroundColor: element.cardsMap[days[i].isoDate].color, 'border-left': '5px solid ' + element.cardsMap[days[i].isoDate].accentColor, 'align-items':'center'}">
          <!-- <div class="mat-caption">
            {{element.cardsMap[days[i].isoDate].cardObject.startDate | date:'mediumDate'}} -
            {{element.cardsMap[days[i].isoDate].cardObject.endDate | date:'mediumDate'}}
          </div> -->
          <div class="text-truncate"
            [matTooltip]="element.cardsMap[days[i].isoDate].label"
            matTooltipPosition="left">
            {{element.cardsMap[days[i].isoDate].label}}
          </div>
        </mat-card>

        <div class="social-calenar-card w-100 text-truncate p-1 mat-elevation-z3"
          [ngStyle]="{backgroundColor: element.cardsMap[days[i].isoDate].color}"
          *ngIf="element.cardsMap[days[i].isoDate].cardType === ECardType.SOCIAL_CALENDAR"
          [matTooltip]="element.cardsMap[days[i].isoDate].label"
          matTooltipPosition="left">
          {{element.cardsMap[days[i].isoDate].label}}
        </div>
      </div>
    </td>
  </ng-container>


  <tr mat-header-row
    class="template-header-row"
    *matHeaderRowDef="allColumns; sticky:true">
  </tr>

  <tr mat-header-row
    class="months-row"
    *matHeaderRowDef="monthColumnKeys; sticky:true">
  </tr>

  <tr mat-header-row
    class="days-row"
    *matHeaderRowDef="dayColumnKeys; sticky: true"></tr>

  <!-- <tr 
      mat-header-row
      class="social-calendar-row"
      *matHeaderRowDef="socialCalendarColumnKeys; sticky: true"></tr> -->

  <tr mat-header-row
    class="cards-row"
    *matHeaderRowDef="cardColumnKeys; sticky: true"></tr>

  <tr mat-row
    class="datasource-row"
    (click)="row.expanded = !row.expanded"
    [ngClass]="{'d-none': (!row.isRowTitle && !row.group?.expanded), 'pointer': row.isRowTitle}"
    *matRowDef="let row; columns: cardColumnKeys;"></tr>
</table>