const clientId = 'DQlXhk45uljUJNnFmk720rfvSfDbWm44';
const baseApiUrl = 'https://pcom-api.sintrasviluppo.it/';
const apiUrl = `${baseApiUrl}api/`;
const host = 'https://pcom-ui.sintrasviluppo.it/';

export const environment = {
  production: true,
  name: 'remote-development',
  appName: 'Sinta - Communication planner',
  host,
  apiUrl,
  authInterceptorUrls: [apiUrl],
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 8000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=f5d350f703822171fac91c4e9da4d65c',
    sendLogs: true,
    sendRequestBody: false,
    sendQueryParams: true,
  },
  registerServiceWorker: false,
  sockets: {
    url: '',
  },
  mockApiUrl: 'https://localhost:3002/',
  clientId,
};
