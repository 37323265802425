import { SuperModel } from './super-model.model';
import { FileModel } from './file-model.model';
import { Brand } from './brand.model';
import { EProjectsStatus } from '@enums';

export interface Category {
  id: number;
  name: string;
}
export interface SubCategory {
  id: number;
  name: string;
}

export interface CountryGroup {
  id: number;
  name: string;
  countries?: Country[];
}

export interface Country {
  id: number;
  code: string;
  name: string;
}

export class Project extends SuperModel {
  name: string;
  brandId: number;
  brand: Brand;
  category: Category;
  categoryId: number;
  subCategory: SubCategory;
  subCategoriesId: number[];
  countryGroups: CountryGroup[];
  countriesIds: number[];
  projectStatus: EProjectsStatus;
  isMan: boolean;
  isWoman: boolean;
  isFashionShow: boolean;
  isPreorder: boolean;
  isDrop: boolean;
  activitiesStatus: string;
  hashtag: string;
  mainImage: FileModel;
  startDate?: string;
  endDate?: string;
  isArchived: boolean;

  constructor(data: any = {}) {
    super(data);

    if (data.mainImage) {
      this.mainImage = new FileModel(data.mainImage);
    } else {
      this.mainImage = new FileModel();
    }
  }
  get serialised() {
    return {
      id: this.id,
      name: this.name,
      brandId: this.brandId,
      categoryId: this.categoryId,
      subCategoriesId: this.subCategoriesId,
      countriesIds: this.countriesIds,
      projectStatus: this.projectStatus,
      isMan: this.isMan,
      isWoman: this.isWoman,
      isFashionShow: this.isFashionShow,
      isPreorder: this.isPreorder,
      isDrop: this.isDrop,
      activitiesStatus: this.activitiesStatus,
      hashtag: this.hashtag,
      startDate: this.startDate,
      endDate: this.endDate,
      isArchived: this.isArchived,
    };
  }

  get locations(): string {
    return (
      this.countryGroups &&
      this.countryGroups
        .map(group => {
          return group.name;
        })
        .join(', ')
    );
  }
}
