import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfFormItemConfig } from '@bitf/core/models/bitf-form-item-config.model';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

import { IBitfApiResponse, IBitfApiRequest } from '@interfaces';
import { Moment, ActivityOverview } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('projects', injector, environment.mockApiUrl, 'mockDbParser');
    super('projects', injector);
  }

  getFormItemConfig(): Observable<IBitfApiResponse<BitfFormItemConfig>> {
    return super.request({
      method: 'GET',
      path: '/form-items-config',
      modelMapper: 'form-items-config',
    } as IBitfApiRequest);
  }

  getMoments(apiRequest: IBitfApiRequest): Observable<IBitfApiResponse<Moment[]>> {
    return super.get({
      ...apiRequest,
      relation: 'moments',
    } as IBitfApiRequest);
  }

  getOverview(apiRequest: IBitfApiRequest): Observable<IBitfApiResponse<ActivityOverview[]>> {
    return super.get({
      ...apiRequest,
      relation: 'activities',
    } as IBitfApiRequest);
  }

  getAudienceBudgetFormItemConfig(): Observable<IBitfApiResponse<BitfFormItemConfig>> {
    return super.request({
      method: 'GET',
      path: '/audience-budget-config',
      modelMapper: 'form-items-config',
    } as IBitfApiRequest);
  }

  download(apiRequest: IBitfApiRequest) {
    return super.fetch({
      ...apiRequest,
      method: 'GET',
      responseType: 'arraybuffer',
    });
  }
}
