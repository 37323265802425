import { SuperModel } from './super-model.model';
import { FileModel } from './file-model.model';

export class Section extends SuperModel {
  name: string;
  introId: string;
  sectionGroupId: string;
  files: FileModel[];

  constructor(data: any = {}) {
    super(data);

    if (data.files) {
      this.files = data.files.map(element => new FileModel(element));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      introId: this.introId,
      sectionGroupId: this.sectionGroupId,
      files: this.files && this.files.map(f => f.serialised),
    };
  }
}
