import { Activity } from './activity.model';

export class MediaPlanningActivity extends Activity {
  platform: string;
  platformId: number;
  subPlatformId: number;
  subPlatform: string;
  country: string;
  city: string;
  formatId: string;
  format: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      ...new Activity(this).serialised,
      platform: this.platform,
      platformId: this.platformId,
      subPlatformId: this.subPlatformId,
      format: this.format,
      formatId: this.formatId,
      country: this.country,
      city: this.city,
    };
  }
}
