export enum ERoleMode {
  DISABLED = 'DISABLED',
  HIDDEN = 'HIDDEN',
  READ_ONLY = 'READ_ONLY',
  INVISIBLE = 'INVISIBLE',
}

export enum ERoles {
  MASTER = 'MASTER',
  USER = 'USER',
  READER = 'READER',
  SECRETARY = 'SECRETARY',
  IT_ADMIN = 'IT_ADMIN',
}

export enum ERoleActions {
  ADD_EDIT_PROJECT = 'ADD_EDIT_PROJECT',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT',
  PUBLISH_PROJECT = 'PUBLISH_PROJECT',
  EDIT_INTRO = 'EDIT_INTRO',
  EDIT_ACTIVITY = 'EDIT_ACTIVITY',
  EDIT_ACTIVITY_STATUS = 'EDIT_ACTIVITY_STATUS',
  EDIT_TARGETS_AND_RESULTS = 'EDIT_TARGETS_AND_RESULTS',
  EDIT_FILE = 'EDIT_FILE',
  EDIT_MOMENT = 'EDIT_MOMENT',
  VIEW_SETTINGS = 'VIEW_SETTINGS',
  EDIT_INFLUENCER_SETTINGS = 'EDIT_INFLUENCER_SETTINGS',
  EDIT_PLATFORMS_SETTINGS = 'EDIT_PLATFORMS_SETTINGS',
  EDIT_SOCIAL_CALENDAR_SETTINGS = 'EDIT_SOCIAL_CALENDAR_SETTINGS',
  EDIT_DASHBOARD = 'EDIT_DASHBOARD',
  DOWNLOAD_PDF_DASHBOARD = 'DOWNLOAD_PDF_DASHBOARD',
  DOWNLOAD_PDF_PROJECT = 'DOWNLOAD_PDF_PROJECT',
  DOWNLOAD_EXCEL_COMMUNICATION_PLAN = 'DOWNLOAD_EXCEL_COMMUNICATION_PLAN',
  VIEW_LOG = 'VIEW_LOG',
}
