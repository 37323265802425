import { Component, OnInit, Inject } from '@angular/core';

import { EProjectsMoments } from '@enums';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';

import { StoreService } from '@services';
import { Moment } from '@models';
import { EBitfCloseEventStatus } from '@bitf/enums';
import { IBitfCloseEvent } from '@web/interfaces';

@Component({
  selector: 'prada-moments-dialog',
  templateUrl: './moments-dialog.component.html',
  styleUrls: ['./moments-dialog.component.scss'],
})
export class MomentsDialogComponent implements OnInit {
  selectedMoments: Moment[] = [];
  currentNumberOfMoments = 0;
  constructor(
    public storeService: StoreService,
    public dialogRef: MatDialogRef<BitfMatOkCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.selectedMoments = [...this.storeService.store.projectMoments];
    this.currentNumberOfMoments = this.selectedMoments.length;
  }

  onOk() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: this.selectedMoments,
    } as IBitfCloseEvent<any>);
  }

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<any>);
  }

  isChecked(moment: Moment): boolean {
    return this.selectedMoments.find(item => item.id === moment.id) !== undefined;
  }

  hasMoment(moment: Moment): boolean {
    if (moment.slug === EProjectsMoments.SHOW) {
      return this.storeService.store.project.isFashionShow;
    } else if (moment.slug === EProjectsMoments.PRE_ORDER) {
      return this.storeService.store.project.isPreorder;
    } else if (moment.slug === EProjectsMoments.DROP) {
      return this.storeService.store.project.isDrop;
    }
  }

  toggleMoment(moment: Moment) {
    const index = this.selectedMoments.findIndex(item => item.id === moment.id);
    if (index >= 0) {
      this.selectedMoments.splice(index, 1);
    } else {
      this.selectedMoments.push(moment);
    }
  }
}
