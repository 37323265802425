import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ElementRef,
} from '@angular/core';
import { DatePipe } from '@angular/common';

import { getDateWithoutTime } from '@bitf/utils/bitf-dates.utils';

import { Gantt, GanttGroupItem, GanttItem, GanttCard, GanttSubGroupItem } from '@models';
import { ECardType } from '@enums';

@Component({
  selector: 'prada-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GanttComponent implements OnInit, OnChanges {
  @Input()
  ganttData: Gantt;
  @Input()
  socialCalendar: Gantt;
  @Output()
  cardClick = new EventEmitter<GanttCard>();

  ECardType = ECardType;

  dataSource: Array<GanttGroupItem | GanttSubGroupItem | GanttItem>;
  allColumns: string[];

  monthColumns: string[];
  monthColumnKeys: string[];
  monthsMap: Map<string, number>;

  dayColumnKeys: string[];
  days: any[];

  cardColumnKeys: string[];
  socialCalendarColumnKeys: string[];

  private firstMonthClassName = '';
  private monthClassName = '';
  private scrollToSecondWeek = false;

  constructor(private datePipe: DatePipe, private elementRef: ElementRef) {}

  ngOnInit() {}

  ngOnChanges() {
    this.initGantt();
    this.parseGantt();
    this.scrollTo();
  }

  private scrollTo() {
    setTimeout(() => {
      if (this.elementRef.nativeElement) {
        let element = this.elementRef.nativeElement.getElementsByClassName(this.monthClassName)[0];
        if (!element) {
          element = this.elementRef.nativeElement.getElementsByClassName(this.firstMonthClassName)[0];
          this.scrollToSecondWeek = false;
        }
        element.scrollIntoView({ behavior: 'auto', inline: 'start' });
        if (this.scrollToSecondWeek) {
          this.elementRef.nativeElement.scrollLeft -= 220 - 40 * 14;
        } else {
          this.elementRef.nativeElement.scrollLeft -= 220;
        }
      }
    });
  }

  private initGantt() {
    this.dataSource = [];
    this.allColumns = ['sticky-left'];
    this.monthColumns = ['sticky-month-left'];
    this.monthColumnKeys = ['sticky-month-column'];
    this.monthsMap = new Map();
    this.dayColumnKeys = ['sticky-day-column'];
    this.days = [];
    this.cardColumnKeys = ['sticky-card-column'];
    this.socialCalendarColumnKeys = ['sticky-social-calendar-column'];
    this.dataSource = [];
  }

  private parseGantt() {
    this.createDays();
    this.createRows();
  }

  private createDays() {
    let currentDate = this.convertIsoDateToLocalDate(this.ganttData.startDate).getTime();
    const endDate = this.ganttData.endDate;
    const endDateTimestamp = this.convertIsoDateToLocalDate(endDate).getTime();
    let i = 0;
    while (currentDate <= endDateTimestamp) {
      this.allColumns.push(i.toString());
      const date = new Date(currentDate);
      this.days.push({
        date: date.getDate(),
        isoDate: getDateWithoutTime(date),
      });
      this.dayColumnKeys.push(`day-${i++}`);
      this.cardColumnKeys.push(`card-${i++}`);
      this.socialCalendarColumnKeys.push(`social-calendar-${i++}`);

      const monthKey = this.datePipe.transform(date, 'MMMM yyyy');
      if (currentDate === this.today) {
        this.monthClassName = `mat-column-${monthKey.replace(' ', '-')}`;
      }
      const monthMapItem = this.monthsMap.get(monthKey);
      if (monthMapItem === undefined) {
        this.monthsMap.set(monthKey, 1);
      } else {
        this.monthsMap.set(monthKey, monthMapItem + 1);
      }
      currentDate += 1000 * 60 * 60 * 24;
    }

    this.monthColumnKeys = this.monthColumnKeys.concat(Array.from(this.monthsMap.keys()));
    this.firstMonthClassName = `mat-column-${this.monthColumnKeys[1].replace(' ', '-')}`;
  }

  convertIsoDateToLocalDate(date): Date {
    const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  private createRows() {
    if (this.socialCalendar && this.socialCalendar.groupItems) {
      this.socialCalendar.groupItems.forEach((groupItem: GanttGroupItem) => {
        // this.dataSource.push(groupItem);
        groupItem.subGroupItems.forEach((subGroup: GanttSubGroupItem) => {
          subGroup.items.forEach((item: GanttItem) => {
            item.group = groupItem;
            this.dataSource.push(item);
          });
        });
      });
    }

    this.ganttData.groupItems.forEach((groupItem: GanttGroupItem) => {
      this.dataSource.push(groupItem);
      groupItem.subGroupItems.forEach((subGroup: GanttSubGroupItem) => {
        if (subGroup.label) {
          subGroup.group = groupItem;
          this.dataSource.push(subGroup);
        }
        subGroup.items.forEach((item: GanttItem) => {
          item.group = groupItem;
          this.dataSource.push(item);
        });
      });
    });
  }

  get today() {
    const date = new Date();
    const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.scrollToSecondWeek = date.getDate() >= 15;
    return this.convertIsoDateToLocalDate(today).getTime();
  }
}
