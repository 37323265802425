import { SuperModel } from './super-model.model';

export class ChannelDashboard extends SuperModel {
  label: string;
  items: ChannelDashboardItem[];

  constructor(data: any = {}) {
    super(data);
    if (data.items) {
      this.items = data.items.map(element => new ChannelDashboardItem(element));
    }
  }

  get serialised() {
    return {
      id: this.id,
      label: this.label,
      items: this.items && this.items.map(el => el.serialised),
    };
  }
}

export class ChannelDashboardItem extends SuperModel {
  label: string;
  active: boolean;
  countries: string[];

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      label: this.label,
      active: this.active,
      countries: this.countries,
    };
  }
}
