import { Injectable, Injector } from '@angular/core';

import { IBitfApiRequest } from '@interfaces';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('files', injector, environment.mockApiUrl, 'mockDbParser');
    super('files', injector);
  }

  download(apiRequest: IBitfApiRequest) {
    return super.fetch({ ...apiRequest, method: 'GET', relation: `download`, responseType: 'arraybuffer' });
  }
}
