import { SuperModel } from './super-model.model';
import { FileModel } from './file-model.model';

export class Report extends SuperModel {
  name: string;
  projectId: string;
  momentId: string;
  files: FileModel[];

  constructor(data: any = {}) {
    super(data);

    if (data.files) {
      this.files = data.files.map(element => new FileModel(element));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      projectId: this.projectId,
      momentId: this.momentId,
      files: this.files && this.files.map(f => f.serialised),
    };
  }
}
