import { BitfStorage } from '@bitf/core/models/bitf-storage.model';
import { Brand } from './brand.model';

// NOTE: default values must be defined in the StorageService, not in the model
// (otherwise the default here will override the super Object.assign())
export class Storage extends BitfStorage {
  selectedBrand?: Brand;
  autosavedCampaignData?: any;

  constructor(storageData: Partial<Storage>) {
    super(storageData);
  }
}
