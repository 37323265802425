import { SuperModel } from './super-model.model';
import { Section } from './section.model';

export class SectionGroup extends SuperModel {
  name: string;
  enableSections: boolean;
  sections: Section[];

  constructor(data: any = {}) {
    super(data);

    if (data.sections) {
      this.sections = data.sections.map(element => new Section(element));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      enableSections: this.enableSections,
      sections: this.sections && this.sections.map(s => s.serialised),
    };
  }
}
