import { SuperModel } from './super-model.model';

export class AudienceBudget extends SuperModel {
  awareness: number;
  conversion: number;
  brandedContent: string;
  celebrities: string;
  display: string;
  events: string;
  influencers: string;
  isManTargetAudience: boolean;
  isWomanTargetAudience: boolean;
  media: string;
  outdoor: string;
  print: string;
  productions: string;
  sem: string;
  social: string;
  targetAudienceAge: string;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      awareness: this.awareness,
      conversion: this.conversion,
      brandedContent: this.brandedContent,
      celebrities: this.celebrities,
      display: this.display,
      events: this.events,
      influencers: this.influencers,
      isManTargetAudience: this.isManTargetAudience,
      isWomanTargetAudience: this.isWomanTargetAudience,
      media: this.media,
      outdoor: this.outdoor,
      print: this.print,
      productions: this.productions,
      sem: this.sem,
      social: this.social,
      targetAudienceAge: this.targetAudienceAge,
    };
  }
}
