import { SuperModel } from './super-model.model';

export interface KpiObject {
  title: string;
  items: KpiItem[];
}

export interface KpiGroup {
  title: string;
  groups: KpiItem[];
}

export interface KpiItem {
  title: string;
  target: number;
  real: number;
  scale: string;
  decimals: number;
}

export class Kpi extends SuperModel {
  overall: KpiObject;
  socialOrganic: KpiObject;
  paidMedia: KpiGroup;
  organicMedia: KpiGroup;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      overall: this.overall,
      socialOrganic: this.socialOrganic,
      paidMedia: this.paidMedia,
      organicMedia: this.organicMedia,
    };
  }
}
