import { BITF_CONFIGS as COMMON_BITF_CONFIGS } from '@common/configs/common-bitf.configs';

const WEB_BITF_CONFIGS = {
  urls: { homePageUrl: '/projects', signInUrl: '/sign-in', signOutUrl: '/sign-in' },
  guard: {
    checkUserRoutePermission: (state, authService) => {
      return true;
    },
  },
  loaderService: {},
  serviceWorker: { checkUpdateInterval: 30000 },
};

export const BITF_CONFIGS = Object.assign(COMMON_BITF_CONFIGS, WEB_BITF_CONFIGS);
