import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prada-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
