import { Activity } from './activity.model';

export class PopupStoreActivity extends Activity {
  platform: string;
  country: string;
  city: string;
  format: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      ...new Activity(this).serialised,
      platform: this.platform,
      country: this.country,
      city: this.city,
      format: this.format,
    };
  }
}
