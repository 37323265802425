<div class="sidenav-menu h-100">
  <div class="h-100 border-right d-flex flex-column">
    <div class="flex-grow-1 d-flex flex-column pt-3">
      <div class="d-flex justify-content-end">
        <button type="button"
          mat-button
          (click)="closeSidenav()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="flex-grow-1">
        <mat-nav-list>
          <mat-list-item *ngFor="let item of menuItems"
            [routerLink]="item.url"
            (click)="closeSidenav()"
            [ngClass]="{'d-none d-sm-block': item.url === '/communication-plan'}">
            <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
            <h4 mat-line>{{ item.label | translate }}</h4>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="text-center mat-small my-2">
      v{{appVersion}} - {{appBuildAt | date}}
    </div>
  </div>
</div>