import { Component, OnInit, Injector } from '@angular/core';

import { IBitfMenuItem } from '@interfaces';

import { MainMenuComponent } from '@web/core/components/main-menu/main-menu.component';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { EBitfSidenavMode, EBitfSidenavPosition } from '@bitf/core/services/sidenav/bitf-sidenav.enum';

@Component({
  selector: 'prada-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
})
export class VerticalMenuComponent implements OnInit {
  mainMenu = MainMenuComponent;
  mSidenavService: BitfMatSidenavService;

  menuItems: Array<IBitfMenuItem> = [
    {
      icon: 'event_note',
      label: bitfToTranslate('WEB.MAIN_MENU.COMMUNICATION_PLAN'),
      url: '/communication-plan',
    },
    {
      icon: 'assignment',
      label: bitfToTranslate('WEB.MAIN_MENU.PROJECTS'),
      url: '/projects',
    },
    {
      icon: 'settings',
      label: bitfToTranslate('COMMON.LABEL.SETTINGS'),
      url: '/settings',
    },
    // {
    //   icon: 'insert_invitation',
    //   label: bitfToTranslate('WEB.MAIN_MENU.REAL_TIME_SCHEDULING'),
    //   // url: '/real-time-campaing',
    //   url: '',
    // },
    // {
    //   icon: 'list_alt',
    //   label: bitfToTranslate('WEB.MAIN_MENU.APPROVAL_LIST'),
    //   // url: '/approval-list',
    //   url: '',
    // },
  ];

  constructor(public injector: Injector) {
    this.mSidenavService = this.injector.get(BitfMatSidenavService);
  }

  ngOnInit() {}

  openLeftMenu() {
    this.mSidenavService.open({
      component: this.mainMenu,
      sidenavOptions: { mode: EBitfSidenavMode.OVER, position: EBitfSidenavPosition.START },
    });
  }
}
