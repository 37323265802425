// tslint:disable:max-line-length
import { BitfPSortApiRequestPart } from '@bitf/core/api-call-state/bitf-p-api-call-state/bitf-p-sort-api-request-part.builder';
// tslint:disable:max-line-length
import { BitfPFormItemConfigApiRequestPart } from '@bitf/core/api-call-state/bitf-p-api-call-state/bitf-p-form-item-config-api-request-part.builder';
import { BitfFormItemConfig } from '@bitf/core/models/bitf-form-item-config.model';
import { BitfFormItem } from '@bitf/core/models/bitf-form-item.model';

import {
  EApiCallStateNames,
  EApiRequestPartKeys,
  EBitfApiSortDirection,
  EBitfApiRequestPartsNames,
  EBitfPQueryComparators,
} from '@enums';
import { IBitfApiCallState, IBitfApiSorting } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import { BitfPaginationApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-pagination-api-request-part';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.DEMO,
    apiRequest: {
      page: 0,
      size: 1,
      sorting: [{ property: 'firstName', direction: 'ASC' }],
      apiUrl: 'http://localhost:3001/',
    },
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.PROJECTS_LIST,
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 0, size: 16 }),
      new BitfPFormItemConfigApiRequestPart({
        key: EApiRequestPartKeys.PROJECTS_LIST_ARCHIVED,
        defaultPartData: { formValue: { isArchived: false } },
      }),
      new BitfPFormItemConfigApiRequestPart(),
      new BitfPSortApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.COMMUNICATION_PLAN,
    requestParts: [
      new BitfPFormItemConfigApiRequestPart({
        partName: EBitfApiRequestPartsNames.QUERY_PARAMS,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.SOCIAL_CALENDAR_LIST,
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 0, size: 20 }),
      new BitfPFormItemConfigApiRequestPart(),
      new BitfPFormItemConfigApiRequestPart({ key: EApiRequestPartKeys.FILTER_YEAR }),
      new BitfPFormItemConfigApiRequestPart({
        key: EApiRequestPartKeys.SEARCH,
        defaultPartData: {
          data: {
            formItemsConfig: {
              name: { comparator: EBitfPQueryComparators.CONTAINS } as BitfFormItem,
            } as BitfFormItemConfig,
          },
        },
      }),
      new BitfPSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_EVENT_LIST_NAME,
        defaultPartData: {
          data: { property: 'name', direction: EBitfApiSortDirection.UNDEFINED } as IBitfApiSorting,
        },
      }),
      new BitfPSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_EVENT_LIST_TYPE,
        defaultPartData: {
          data: { property: 'type', direction: EBitfApiSortDirection.ASC } as IBitfApiSorting,
        },
      }),
      new BitfPSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_EVENT_LIST_START_DATE,
        defaultPartData: {
          data: { property: 'startDate', direction: EBitfApiSortDirection.ASC } as IBitfApiSorting,
        },
      }),
      new BitfPSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_EVENT_LIST_END_DATE,
        defaultPartData: {
          data: { property: 'endDate', direction: EBitfApiSortDirection.UNDEFINED } as IBitfApiSorting,
        },
      }),
      new BitfPSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_EVENT_LIST_REPEAT,
        defaultPartData: {
          data: { property: 'repeat', direction: EBitfApiSortDirection.UNDEFINED } as IBitfApiSorting,
        },
      }),
      new BitfPSortApiRequestPart({
        key: EApiRequestPartKeys.SORT_EVENT_LIST_RECURRENT,
        defaultPartData: {
          data: { property: 'recurrent', direction: EBitfApiSortDirection.UNDEFINED } as IBitfApiSorting,
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.INFLUENCER_LIST,
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 0, size: 32 }),
      new BitfPFormItemConfigApiRequestPart({
        key: EApiRequestPartKeys.SEARCH,
        defaultPartData: {
          data: {
            formItemsConfig: {
              name: { comparator: EBitfPQueryComparators.CONTAINS } as BitfFormItem,
            } as BitfFormItemConfig,
          },
        },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.PROJECT_GANTT,
    requestParts: [
      new BitfPFormItemConfigApiRequestPart({
        partName: EBitfApiRequestPartsNames.QUERY_PARAMS,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
