import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class TargetsAndResultsService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('targets-and-results', injector, 'https://localhost:3002/', 'mockDbParser');
    super('targets-and-results', injector);
  }
}
