import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';
import { IBitfApiRequest, IBitfApiResponse } from '@bitf/interfaces';
import { BitfFile } from '@bitf/core/models/bitf-file.model';
import { BitfFormItemConfig } from '@bitf/core/models/bitf-form-item-config.model';

import { EActivityStatus, Activity, FileModel } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('activities', injector, 'https://localhost:3002/', 'mockDbParser');
    super('activities', injector);
  }

  getFormItemConfig(apiRequest: IBitfApiRequest): Observable<IBitfApiResponse<BitfFormItemConfig>> {
    return super.request({
      ...apiRequest,
      method: 'GET',
      relation: 'form-items-config',
    } as IBitfApiRequest);
  }

  changeStatus(activityId: number, status: EActivityStatus): Observable<IBitfApiResponse<Activity>> {
    return super.patch({
      path: `/${activityId}/status`,
      body: {
        status,
      },
      isBodyRaw: true,
    } as IBitfApiRequest);
  }

  uploadActivityFiles(files: BitfFile[], id: number): Observable<IBitfApiResponse<FileModel[]>[]> {
    return super.uploadMultiple<FileModel[]>({
      id,
      files,
      fileFormFieldName: 'file',
      relation: 'files',
    } as IBitfApiRequest);
  }
}
