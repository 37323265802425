import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';
import { azurePromptToDownloadFile, bitfPromptToDownloadFile } from '@bitf/utils/bitf-files.utils';

import { EFileActionType, ERoleActions, ERoleMode, EBitfCloseEventStatus } from '@enums';
import { Section, FileModel, Report } from '@models';
import { DialogsService, SectionsService, FilesService, LoaderService, ReportService } from '@services';
import { CONSTANTS } from '@constants';
import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';
import { BlobSharedService } from '@common/libs/bitforce/core/services/azure/blob-shared.service';
import { BlobDownloadResponseParsed } from '@azure/storage-blob';

@Component({
  selector: 'prada-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit, OnChanges {
  dataSource: MatTableDataSource<FileModel>;
  sectionGroupsTableColumn: string[] = ['preview', 'name', 'upload_date', 'upload_by', 'actions'];

  @Input()
  section?: Section = null;

  @Input()
  report?: Report = null;

  @Input()
  files: FileModel[];

  ERoleActions = ERoleActions;
  ERoleMode = ERoleMode;

  constructor(
    private dialogsService: DialogsService,
    private loaderService: LoaderService,
    private sectionsService: SectionsService,
    private reportsService: ReportService,
    private translateService: TranslateService,
    private filesService: FilesService,
    private bitfErrorHandlerService: BitfErrorHandlerService,
    private blobsharedService: BlobSharedService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<FileModel>(this.files);
  }

  onAction(type: EFileActionType, file: FileModel) {
    switch (type) {
      case EFileActionType.DELETE:
        this.deleteFileAzure(file);
        break;
      case EFileActionType.SHARE:
        // TODO: Share
        break;
      case EFileActionType.DOWNLOAD:
        this.downloadFileAzure(file);
        break;
    }
  }

  // private downloadFile(file: FileModel) {
  //   this.filesService
  //     .download({ id: file.id } as IBitfApiRequest)
  //     .subscribe((fileToDownload: HttpResponse<IBitfApiResponse<any>>) => {
  //       bitfPromptToDownloadFile(fileToDownload.body.originalBody, file.type, file.extension, file.name);
  //     });
  // }

  private downloadFileAzure(file: FileModel) {
    this.blobsharedService.downloadAzure(file).subscribe((fileToDownload: BlobDownloadResponseParsed) => {
      azurePromptToDownloadFile(fileToDownload, file.type, file.extension, file.name);
    });
  }

  // private deleteFile(file: FileModel) {
  //   const deleteFile = file;
  //   const deleteFileIndex = this.files.indexOf(file);

  //   const dialogRef = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
  //     width: 'auto',
  //     maxWidth: '800px',
  //     data: {
  //       title: this.translateService.instant('COMMON.FILE.DELETE'),
  //       message: this.translateService.instant('COMMON.FILE.DELETE_MESSAGE', {
  //         value: file.name,
  //       }),
  //       cancelText: this.translateService.instant('COMMON.BUTTON.NO'),
  //       okText: this.translateService.instant('COMMON.BUTTON.OK'),
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result && result.status === EBitfCloseEventStatus.OK) {
  //       this.files.splice(deleteFileIndex, 1);
  //       this.dataSource = new MatTableDataSource<FileModel>(this.files);

  //       this.loaderService.show();

  //       if (this.section) {
  //         this.sectionsService.delete({ id: this.section.id, path: `/files/${file.id}` }).subscribe(
  //           () => { },
  //           error => {
  //             this.bitfErrorHandlerService.handleError(error);
  //             this.revert(deleteFileIndex, deleteFile);
  //           }
  //         );
  //       } else if (this.report) {
  //         this.reportsService.delete({ id: this.report.id, path: `/files/${file.id}` }).subscribe(
  //           () => { },
  //           error => {
  //             this.bitfErrorHandlerService.handleError(error);
  //             this.revert(deleteFileIndex, deleteFile);
  //           }
  //         );
  //       } else {
  //         this.filesService.delete({ id: file.id }).subscribe(
  //           () => { },
  //           error => {
  //             this.bitfErrorHandlerService.handleError(error);
  //             this.revert(deleteFileIndex, deleteFile);
  //           }
  //         );
  //       }
  //     }
  //   });
  // }

  private deleteFileAzure(file: FileModel) {
    const deleteFile = file;
    const deleteFileIndex = this.files.indexOf(file);

    const dialogRef = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
      width: 'auto',
      maxWidth: '800px',
      data: {
        title: this.translateService.instant('COMMON.FILE.DELETE'),
        message: this.translateService.instant('COMMON.FILE.DELETE_MESSAGE', {
          value: file.name,
        }),
        cancelText: this.translateService.instant('COMMON.BUTTON.NO'),
        okText: this.translateService.instant('COMMON.BUTTON.OK'),
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === EBitfCloseEventStatus.OK) {
        this.files.splice(deleteFileIndex, 1);
        this.dataSource = new MatTableDataSource<FileModel>(this.files);

        this.loaderService.show();

        this.blobsharedService.deleteAzure(deleteFile).subscribe(
          () => {
            this.deleteRecord(deleteFile, deleteFileIndex, file);
          },
          error => {
            this.bitfErrorHandlerService.handleError(error);
            this.revert(deleteFileIndex, deleteFile);
          }
        );
      }
    });
  }

  deleteRecord(deleteFile: FileModel, deleteFileIndex: number, file: FileModel) {
    if (this.section) {
      this.sectionsService.delete({ id: this.section.id, path: `/files/${file.id}` }).subscribe(
        () => {},
        error => {
          this.bitfErrorHandlerService.handleError(error);
          this.revert(deleteFileIndex, deleteFile);
        }
      );
    } else if (this.report) {
      this.reportsService.delete({ id: this.report.id, path: `/files/${file.id}` }).subscribe(
        () => {},
        error => {
          this.bitfErrorHandlerService.handleError(error);
          this.revert(deleteFileIndex, deleteFile);
        }
      );
    } else {
      this.filesService.delete({ id: file.id }).subscribe(
        () => {},
        error => {
          this.bitfErrorHandlerService.handleError(error);
          this.revert(deleteFileIndex, deleteFile);
        }
      );
    }
  }

  private revert(index: number, file: FileModel) {
    this.files.splice(index, 0, file);
    this.dataSource = new MatTableDataSource<FileModel>(this.files);
  }
}
