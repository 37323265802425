import { SuperModel } from './super-model.model';
import { SocialBrand } from './social-brand.model';

export class Social extends SuperModel {
  label: string;
  followers: number;
  postsPerWeek: number;
  isDefault: boolean;
  brands: SocialBrand[];

  constructor(data: Partial<Social>) {
    super(data);

    if (data.brands) {
      this.brands = data.brands.map(brand => new SocialBrand(brand));
    }
  }

  get serialised() {
    return {
      id: this.id,
      label: this.label,
      followers: this.followers ? parseFloat(this.followers.toString().replace(/,/g, '')) : 0,
      postsPerWeek: this.postsPerWeek ? parseFloat(this.postsPerWeek.toString().replace(/,/g, '')) : 0,
      isDefault: this.isDefault,
      brands: this.brands,
    };
  }
}
