<h2 mat-dialog-title>{{ dialogData.title | translate }}</h2>
<mat-dialog-content class="justify-content-center">
  <mat-divider></mat-divider>

  <mat-list role="list">
    <mat-list-item *ngFor="let moment of storeService.store.moments">
      <mat-checkbox color="primary"
        [checked]="isChecked(moment)"
        [disabled]="hasMoment(moment)"
        (change)="toggleMoment(moment)">{{moment.label}}</mat-checkbox>
    </mat-list-item>
  </mat-list>

</mat-dialog-content>
<mat-dialog-actions class="row"
  [ngClass]="!dialogData.cancelText || !dialogData.okText ? 'justify-content-center' : 'justify-content-end'">
  <button mat-flat-button
    (click)="onCancel()"
    *ngIf="dialogData.cancelText">
    {{ dialogData.cancelText | translate | uppercase }}
  </button>

  <button mat-flat-button
    (click)="onOk()"
    [disabled]="currentNumberOfMoments === selectedMoments.length"
    class="ml-3"
    color="primary"
    [mat-dialog-close]="true"
    *ngIf="dialogData.okText">
    {{ dialogData.okText | translate | uppercase }}
  </button>
</mat-dialog-actions>