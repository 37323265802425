import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Optional, Inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import { BitfDateAdapterService } from '@bitf/services/locale/bitf-date-adapter.service';

@Injectable({
  providedIn: 'root',
})
export class DateAdapterService extends BitfDateAdapterService {
  firstDayMap = {
    it: 1,
    en: 1,
  };
  localeFormatMap = {
    it: 'dd/MM/yyyy',
    en: 'dd/MM/yyyy',
  };
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }
}
