<mat-card *ngIf="countryGroups && countryGroups.length > 0"
  class="mb-2 p-3">
  <mat-card-content>
    <span class="text-wrap d-block py-2"
      *ngFor="let countryGroup of countryGroups"><span class="font-weight-bold">{{countryGroup.name}}</span>:
      <ng-container *ngFor="let country of countryGroup.countries; let i = index"> <span
          *ngIf="country.code !== '_G'">{{country.name}}</span>
        <span *ngIf="i < countryGroup.countries.length - 1">, </span>
      </ng-container>
      <br />
    </span>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content class="d-flex flex-column">
    <div class="d-flex justify-content-between">
      <h3>{{ fieldName }}</h3>

      <button mat-icon-button
        *ngIf="expandable">
        <mat-icon color="primary"
          (click)="expanded = !expanded"
          *ngIf="!expanded">keyboard_arrow_down</mat-icon>
        <mat-icon color="primary"
          (click)="expanded = !expanded"
          *ngIf="expanded">keyboard_arrow_up</mat-icon>
      </button>
    </div>
    <mat-form-field class="w-100"
      appearance="outline"
      *ngIf="searchEnabled && expanded">
      <mat-label>{{ searchPlaceholder }}</mat-label>
      <input matInput
        placeholder="{{ searchPlaceholder }}"
        [formControl]="searchField" />
    </mat-form-field>

    <mat-tree [ngClass]="expanded ? 'd-block' : 'd-none'"
      [dataSource]="dataSource"
      [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node"
        [ngClass]="node.hidden ? 'd-none' : 'd-flex'"
        matTreeNodeToggle
        matTreeNodePadding>
        <button mat-icon-button
          disabled></button>
        <mat-checkbox color="primary"
          class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(node)"
          (change)="leafSelectionToggle(node)">{{ node.label }}</mat-checkbox>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
        [ngClass]="descendantsHasVisbileNode(node) ? 'd-flex' : 'd-none'"
        matTreeNodePadding>
        <button mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'toggle ' + node.filename">
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-checkbox color="primary"
          [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="parentSelectionToggle(node)">{{ node.label }}</mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </mat-card-content>
</mat-card>
