import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformsService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('platforms', injector, 'https://localhost:3002/', 'mockDbParser');
    super('platforms', injector);
  }
}
