import { Project } from './project.model';
import { SectionGroup } from './section-group.model';
import { FileModel } from '@models';

export class Intro extends Project {
  isManTargetAudience: boolean;
  isWomanTargetAudience: boolean;
  mainImage: FileModel;
  message: string;
  pressPackage: string;
  projectInfo: string;
  sectionGroups: SectionGroup[];

  constructor(data: any = {}) {
    super(data);

    if (data.sectionGroups) {
      this.sectionGroups = data.sectionGroups.map(element => new SectionGroup(element));
    }
    if (data.mainImage) {
      this.mainImage = new FileModel(data.mainImage);
    } else {
      this.mainImage = new FileModel();
    }
  }

  get serialised() {
    return {
      ...new Project(this).serialised,
      isManTargetAudience: this.isManTargetAudience,
      isWomanTargetAudience: this.isWomanTargetAudience,
      mainImage: this.mainImage,
      message: this.message,
      pressPackage: this.pressPackage,
      projectInfo: this.projectInfo,
    };
  }
}
