<bitf-dnd-file (dropFiles)="onFileDropped($event)"
  *ngIf="showDndArea">
  <div class="col-auto">
    {{ 'BITF.FILE_UPLOADER.DROP_FILES_HERE_OR_SELECT' | translate }} </div>
  <div class="col-auto">
    <button mat-stroked-button
      color="primary"
      (click)="selectFile.click()"> {{ 'BITF.FILE_UPLOADER.SELECT_FILE' | translate | uppercase }}
    </button>
    <input class="d-none"
      type="file"
      #selectFile
      [attr.multiple]="(maxNumberOfFiles > 1) ? '' : null"
      (change)="onFileDropped($event)" />
  </div>
</bitf-dnd-file>

<div *ngIf="!showDndArea">
  <button mat-stroked-button
    color="primary"
    (click)="imgFileInput.click()">
    <mat-icon class="mr-2">cloud_upload</mat-icon> {{ 'BITF.FILE_UPLOADER.UPLOAD' | translate | uppercase }}
  </button>
  <input type="file"
    class="d-none"
    #imgFileInput
    [attr.multiple]="(maxNumberOfFiles > 1) ? '' : null"
    (change)="onFileDropped($event)" />
</div>

<div *ngIf="files.length && showFileList"
  class="mt-3">
  <h3>{{'BITF.FILE_UPLOADER.SELECETED_FILES' | translate}}</h3>

  <mat-list role="list"
    [ngClass]="{'file-list-scrollable': config.isScrollable}">
    <mat-list-item *ngFor="let file of files; let index = index;">
      <div class="row  align-items-center w-100">
        <div class="col-auto">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          {{ file.fileObject.name }} ({{file.fileObject.size | bitfBytes}})
        </div>
        <mat-progress-bar class="col-5 ml-auto"
          mode="determinate"
          [value]="file.uploadedPercentage"></mat-progress-bar>

        <button mat-icon-button
          class="col-auto"
          (click)="removeFile(index)">
          <mat-icon>delete</mat-icon>
        </button>

        <mat-icon *ngIf="file.validationErrors.length"
          color="warn"
          [matTooltip]="file.validationErrorsTooltip">warning</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>

  <div class="d-flex justify-content-end prada-white-bg mt-3"
    *ngIf="!uploadOnFilesDropped">
    <button mat-button
      color="primary"
      (click)="removeAllFiles()"
      class="col-auto mr-2">
      {{ 'COMMON.BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button
      color="primary"
      (click)="upload()"
      class="col-auto">
      {{ 'BITF.FILE_UPLOADER.UPLOAD' | translate }}
    </button>
  </div>
</div>