import { SuperModel } from './super-model.model';
import { getDateWithoutTime } from '@bitf/utils/bitf-dates.utils';

export class Gantt extends SuperModel {
  startDate: Date;
  endDate: Date;
  groupItems: GanttGroupItem[];

  constructor(data: any = {}) {
    super(data);

    if (data.groupItems) {
      this.groupItems = data.groupItems.map(x => new GanttGroupItem(x));
    }
    this.startDate = new Date(data.startDate);
    this.endDate = new Date(data.endDate);
  }

  get serialised() {
    return {};
  }
}

export class GanttGroupItem extends SuperModel {
  label: string;
  subGroupItems: GanttSubGroupItem[];
  isRowTitle = true;
  isSubRowTitle = false;
  expanded = true;

  constructor(data: any = {}) {
    super(data);

    if (data.subGroupItems) {
      this.subGroupItems = data.subGroupItems.map(x => new GanttSubGroupItem(x));
    }
  }

  get serialised() {
    return {};
  }
}

export class GanttSubGroupItem extends SuperModel {
  group: GanttGroupItem;
  label: string;
  items: GanttItem[];
  isRowTitle = false;
  isSubRowTitle = true;

  constructor(data: any = {}) {
    super(data);

    if (data.items) {
      this.items = data.items.map(x => new GanttItem(x));
    }
  }

  get serialised() {
    return {};
  }
}

export class GanttItem extends SuperModel {
  group: GanttGroupItem;
  isRowTitle = false;
  isSubRowTitle = false;
  cardType: ECardType.ACTIVITY;
  header: string;
  label: string;
  note: string;
  startDate: Date;
  startDateIsoString: string;
  endDate: Date;
  cards: GanttCard[];
  color: string;
  cardsMap = {};

  constructor(data: any = {}) {
    super(data);

    this.startDate = this.startDate && new Date(data.startDate);
    this.endDate = this.endDate && new Date(data.endDate);

    if (data.cards) {
      this.cards = data.cards.map(x => new GanttCard(x));
      this.cards.forEach(card => {
        this.cardsMap[getDateWithoutTime(card.startDate)] = card;
      });
    }
  }

  get serialised() {
    return {};
  }
}

export class GanttCard extends SuperModel {
  isRowTitle = false;
  header: string;
  label: string;
  note: string;
  startDate: Date;
  endDate: Date;
  cardType: ECardType.ACTIVITY;
  color: string;
  accentColor: string;
  cardObject?: any;
  width: string;
  dayWidth = 40;

  constructor(data: any = {}) {
    super(data);

    this.startDate = new Date(data.startDate);
    this.endDate = new Date(data.endDate);

    // NO getter in the view for performance
    this.width = this.getWidth();
  }

  getWidth() {
    const endDateTimestamp = this.endDate.getTime();
    const startDateTimestamp = this.startDate.getTime();
    let lengthInDays = (endDateTimestamp - startDateTimestamp) / 1000 / 60 / 60 / 24;
    // NOTE we want to consider the start / end days included
    return `${++lengthInDays * this.dayWidth}px`;
  }

  get serialised() {
    return {};
  }
}

export enum ECardType {
  ACTIVITY = 'ACTIVITY',
  SOCIAL_CALENDAR = 'SOCIAL_CALENDAR',
}
