import { SuperModel } from './super-model.model';
import { TargetAndResult } from './target-and-result.model';

export class TargetAndResultGroup extends SuperModel {
  label: string;
  items: TargetAndResult[];

  constructor(data: any = {}) {
    super(data);

    if (this.items) {
      this.items = this.items.map(item => new TargetAndResult(item));
    }
  }

  get serialised() {
    return {
      id: this.id,
      label: this.label,
      items: this.items,
    };
  }
}
