import { SuperModel } from './super-model.model';
import { EventType } from '@models';

export class SocialCalendar extends SuperModel {
  name: string;
  type: EventType;
  startDate: string;
  endDate: string;
  repeat: boolean;
  recurrent: boolean;

  constructor(data: any = {}) {
    super(data);
    Object.assign(this, data);

    if (data.type) {
      this.type = new EventType(data.type);
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      startDate: this.startDate,
      endDate: this.endDate,
      repeat: this.repeat,
      recurrent: this.recurrent,
    };
  }
}
