import { SuperModel } from './super-model.model';
import { EPlatformFeature } from './platform.enum';

export class Platform extends SuperModel {
  name: string;
  features: EPlatformFeature[];
  featureItems: PlatformFeatureItem[];
  items: PlatformItem[];

  constructor(data: any = {}) {
    super(data);

    if (data.items) {
      this.items = data.items.map(item => new PlatformItem(item));
    }
  }

  get serialised() {
    return {
      id: this.id,
      features: this.features,
      items: this.items,
    };
  }

  sortByOrderAsc(p1: PlatformItem, p2: PlatformItem) {
    if (p1.order > p2.order) {
      return 1;
    }
    return -1;
  }
}

export interface PlatformFeatureItem {
  channelId: number;
  channelGroupName: string;
  isSelected: boolean;
}

export class PlatformItem extends SuperModel {
  name: string;
  order: number;
  channels: PlatformChannel[];
  subItems: PlatformItem[];

  constructor(data: any = {}) {
    super(data);

    if (data.channels) {
      this.channels = data.channels.map(item => new PlatformChannel(item));
    }

    if (data.subItems) {
      this.subItems = data.subItems.map(item => new PlatformItem(item));
    }
  }

  get channelsList(): string {
    return this.channels
      .filter(channel => channel.isSelected)
      .map(channel => channel.name)
      .join(', ');
  }

  get subItemsList(): string {
    return this.subItems.map(subItem => subItem.name).join(', ');
  }

  getListKey(features: EPlatformFeature[]): string {
    if (features.includes(EPlatformFeature.PRESENT_ON)) {
      return this.channelsList;
    } else if (features.includes(EPlatformFeature.SUBPLATFORM)) {
      return this.subItemsList;
    }

    return null;
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      order: this.order,
      channels: this.channels,
      subItems: this.subItems,
    };
  }

  sortByOrderAsc(p1: PlatformItem, p2: PlatformItem) {
    if (p1.order > p2.order) {
      return 1;
    }
    return -1;
  }
}

export class PlatformChannel extends SuperModel {
  name: string;
  channelId: number;
  isSelected: boolean;
  order: number;

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      channelId: this.channelId,
      isSelected: this.isSelected,
      order: this.order,
    };
  }
}
