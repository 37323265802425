import { Component, OnInit, Input } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';

import { CONSTANTS } from '@constants';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'prada-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  @Input()
  dataTable: any;

  @Input()
  chartOptions: any;

  @Input()
  chartValue: string;

  dataChart: GoogleChartInterface;
  centerPositionX;

  constructor() {}

  ngOnInit() {
    if (this.dataTable.length > 0) {
      this.loadChart();
    }

    fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => {
        if (this.dataChart.component) {
          this.dataChart.component.draw();
        }
      });
  }

  private loadChart() {
    this.dataChart = {
      chartType: 'PieChart',
      dataTable: this.dataTable,
      options: {
        pieHole: 0.5,
        tooltip: { trigger: 'none' },
        colors: [
          CONSTANTS.blueGrey,
          CONSTANTS.lightBlue,
          CONSTANTS.lightGreen,
          CONSTANTS.darkGrey,
          CONSTANTS.red,
          CONSTANTS.yellow,
          CONSTANTS.green,
        ],
        sliceVisibilityThreshold: 0,
        chartArea: { width: '100%', height: '100%' },
        legend: {
          position: 'right',
          alignment: 'center',
          textStyle: { color: '#00000', fontName: 'Roboto', fontSize: '12px', bold: false, italic: 'false' },
        },
        enableInteractivity: false,
        ...this.chartOptions,
      },
    };
  }

  ready(event) {
    window['cli'] = this.dataChart.component['el'];
    this.centerPositionX =
      this.dataChart.component['el'].nativeElement.querySelectorAll('svg > g')[0].getBBox().x / 2 - 8;
  }
}
