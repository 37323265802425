import { Injectable, Injector } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

// tslint:disable-next-line:max-line-length
import { BitfLogDnaSenderService } from '@bitf/core/services/logger/bitf-log-dna-sender.service';
import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
import { Subject } from 'rxjs';

import { ResponsiveService } from '@services';
import { IUserAction } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  // NOTE: needed for mobile/desktop rounting support
  activeBreakpoints = {
    isDesktop: false,
    isMobile: false,
  };

  public projectMenuAction$ = new Subject<IUserAction>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private responsiveService: ResponsiveService,
    public injector: Injector
  ) {
    super(injector);
  }

  init() {
    super.init();
    this.initSession();

    // NOTE: needed for mobile/desktop rounting support
    // initDynamicLayoutUrl(this.router, this);
    this.initBreakpointListener();
    this.responsiveService.init();
    // --------------------------
  }

  initLogSender() {
    this.bitfLogDnaSenderService.init();
  }

  initBreakpointListener() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(result => {
        if (result.matches) {
          this.activeBreakpoints.isDesktop = true;
          this.activeBreakpoints.isMobile = false;
        } else {
          this.activeBreakpoints.isDesktop = false;
          this.activeBreakpoints.isMobile = true;
        }
        // updateAppLayout(this.router, result.matches);
      });
  }

  initSession() {}

  initSettings() {}

  cleanSession() {}
}
