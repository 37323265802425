import { Component, OnInit, Input, Injector, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

// tslint:disable-next-line:max-line-length
import { BitfFileUploaderComponent } from '@bitf/core/components/ui/file-uploader/bitf-file-uploader.component';

import {
  ActivitiesService,
  SectionsService,
  IntrosService,
  InfluencersService,
  ProductsService,
  StoreService,
  ReportService,
} from '@services';
import { Activity, Section, FileModel, Intro, Influencer, Product, Report } from '@models';
import { IBitfApiResponse, IBitfApiRequest, IFileUploaderConfig } from '@interfaces';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';
import { BlobSharedService } from '@common/libs/bitforce/core/services/azure/blob-shared.service';
import { HttpEvent } from '@angular/common/http';
import { forEach } from '@angular-devkit/schematics';

@Component({
  selector: 'prada-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent extends BitfFileUploaderComponent<FileModel> implements OnInit {
  @Input()
  config: IFileUploaderConfig = {
    isScrollable: false,
  };

  @Input()
  uploadFor: Activity | Section | Intro | Influencer | Report;

  @ViewChild('selectFile') selectFileInput;

  constructor(
    private activitiesService: ActivitiesService,
    private sectionsService: SectionsService,
    private introsService: IntrosService,
    private influencersService: InfluencersService,
    private productService: ProductsService,
    public storeService: StoreService,
    private reportsService: ReportService,
    private blobsharedService: BlobSharedService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onFileDropped(event: FileList | any) {
    super.onFileDropped(event);
    if (this.selectFileInput && this.selectFileInput.nativeElement) {
      this.selectFileInput.nativeElement.value = '';
    }
  }

  upload() {
    super.upload();
    const apiRequest: IBitfApiRequest = {
      id: this.uploadFor.id,
      fileFormFieldName: 'file',
      relation: 'files',
    };
    if (this.uploadFor instanceof Activity) {
      this.uploadMultipleFileAzure(this.activitiesService, apiRequest);
    }

    if (this.uploadFor instanceof Section) {
      this.uploadMultipleFileAzure(this.sectionsService, apiRequest);
    }

    if (this.uploadFor instanceof Report) {
      this.uploadMultipleFileAzure(this.reportsService, apiRequest);
    }

    if (this.uploadFor instanceof Intro) {
      this.uploadSingleFileAzure(this.introsService, apiRequest);
    }

    if (this.uploadFor instanceof Influencer) {
      this.uploadSingleFileAzure(this.influencersService, apiRequest);
    }

    if (this.uploadFor instanceof Product) {
      this.uploadSingleFileAzure(this.productService, apiRequest);
    }
  }

  uploadSingleFileAzure(service: BitfApiService, apiRequest: IBitfApiRequest) {
    return this.blobsharedService
      .uploadAzure({
        ...apiRequest,
        relation: 'images',
        file: this.uploadableFiles[0],
      } as IBitfApiRequest)
      .subscribe((response: FileModel) => {
        this.uploadMetadata(response, service, apiRequest);
      });
  }

  uploadMetadata(response: FileModel, service: BitfApiService, apiRequest: IBitfApiRequest) {
    service
      .patch<FileModel>({
        ...apiRequest,
        body: response,
        isBodyRaw: true,
        relation: service instanceof InfluencersService ? 'picture' : 'image',
        modelMapper: 'files',
      } as IBitfApiRequest)
      .subscribe((responseFile: IBitfApiResponse<FileModel>) => {
        this.filesUploaded.emit([responseFile.content]);
        this.removeUploadedFiles();
      });
  }

  uploadMultipleFileAzure(service: BitfApiService, apiRequest: IBitfApiRequest) {
    const uploadRequests = Array<Observable<FileModel>>();
    this.uploadableFiles.forEach(uploadableFile => {
      uploadRequests.push(
        this.blobsharedService.uploadAzure({
          ...apiRequest,
          relation: 'images',
          file: uploadableFile,
        } as IBitfApiRequest)
      );
    });
    forkJoin(uploadRequests).subscribe((responses: FileModel[]) => {
      this.uploadMetadataMultiple(responses, service, apiRequest);
    });
  }

  uploadMetadataMultiple(response: FileModel[], service: BitfApiService, apiRequest: IBitfApiRequest) {
    service
      .post<FileModel[]>({
        ...apiRequest,
        body: response,
        isBodyRaw: true,
        modelMapper: 'files',
      } as IBitfApiRequest)
      .subscribe((responseFile: IBitfApiResponse<FileModel[]>) => {
        this.filesUploaded.emit(responseFile.content);
        this.removeUploadedFiles();
      });
  }
}
