import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { EBitfCloseEventStatus } from '@bitf/enums';

import { IBitfInputDialogData, IBitfCloseEvent } from '@interfaces';

@Component({
  selector: 'prada-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit {
  formControl: FormControl = new FormControl(null, Validators.required);

  constructor(
    public dialogRef: MatDialogRef<BitfMatOkCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IBitfInputDialogData
  ) {}

  ngOnInit() {
    this.formControl.patchValue(this.dialogData.defaultValue);
  }

  onOk() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: this.formControl.value,
    } as IBitfCloseEvent<any>);
  }

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<any>);
  }
}
