import { SuperModel } from './super-model.model';

export interface BudgetData {
  label: string;
  total: number;
  unitOfMeasure: string;
  items: BudgetItem[];
}

export interface BudgetItem {
  label: string;
  percentage: number;
  value: number;
}

export interface BudgetAreaData {
  label: string;
  total: number;
  unitOfMeasure: string;
  items: BudgetData[];
}

export class Budget extends SuperModel {
  budget: BudgetData;
  mediaBudget: BudgetData;
  mediaBudgetByArea: BudgetAreaData;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      budget: this.budget,
      mediaBudget: this.mediaBudget,
      mediaBudgetByArea: this.mediaBudgetByArea,
    };
  }
}
