<div class="h-100 sign-in-wrapper d-flex justify-content-center align-items-center p-3"
  [ngStyle]="{backgroundImage: 'url(' + backgroundImage + ')'}">
  <mat-card class="col-24 col-sm-auto brand-selector mat-elevation-z2">
    <mat-card-content class="d-flex flex-column justify-content-around align-items-center h-100">
      <img src="/assets/images/app-logo.png"
        class="prada-logo" />
      {{authState}}
      <div *ngIf="
          authState === bitfEAuthState.TO_BE_LOGGED ||
          authState === bitfEAuthState.LOGIN_IN_PROGRESS ||
          (authState === bitfEAuthState.LOGGED && !form)
        ">
        <h3>{{ 'COMMON.LABEL.LOGIN_IN_PROGRESS_PLEASE_WAIT' | translate }}</h3>
        <mat-progress-bar mode="indeterminate"
          color="accent"></mat-progress-bar>
      </div>

      <div *ngIf="authState === bitfEAuthState.TO_BE_LOGGED">
        <h3 class="text-center">{{ 'COMMON.LABEL.LOGIN_ERROR' | translate }}</h3>

        <button mat-raised-button
          color="primary"
          (click)="retrySignIn()"
          class="my-3 w-100">
          {{ 'COMMON.BUTTON.RETRY_SIGN_IN' | translate }}
        </button>
      </div>

      <div *ngIf="authState === bitfEAuthState.LOGGED && form"
        class="w-100 d-flex flex-column justify-content-center align-items-center">
        <form [formGroup]="form">
          <bitf-mat-form-option [formItem]="brands"
            selectFormControlName="brandId"
            [useDefaultOption]="true"
            [form]="form"></bitf-mat-form-option>
        </form>

        <button mat-raised-button
          [disabled]="!form.valid"
          color="primary"
          (click)="selectBrand()"
          class="my-3 w-100">
          {{ 'COMMON.BUTTON.SELECT_BRAND' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>