import { Activity } from './activity.model';

export class PressScheduleActivity extends Activity {
  platformId: string;
  platform: string;
  formatId: string;
  format: string;
  magazine: string;
  country: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      ...new Activity(this).serialised,
      platformId: this.platformId,
      platform: this.platform,
      formatId: this.formatId,
      format: this.format,
      magazine: this.magazine,
      country: this.country,
    };
  }
}
