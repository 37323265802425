<mat-menu #brandsMenu="matMenu">
    <button mat-menu-item
        class="brand-menu-item"
        (click)="choseBrand(brand)"
        *ngFor="let brand of brands">{{brand.label}}</button>
</mat-menu>

<button mat-button
    [matMenuTriggerFor]="brandsMenu"
    class="brand-logo">
    <img *ngIf="brandImageUrl"
        src="{{ brandImageUrl }}"
        class="px-2"
        style="min-height: 50px;min-width: 100px;" />
</button>