import { SuperModel } from './super-model.model';
import { FileModel } from '@models';

export class Product extends SuperModel {
  file: FileModel;
  code: string;
  category: string;
  price: string;

  constructor(data: Partial<Product> = {}) {
    super(data);

    this.file = this.file ? new FileModel(this.file) : new FileModel();
  }

  get serialised() {
    return {
      id: this.id,
      code: this.code,
      category: this.category,
      price: this.price,
    };
  }
}
