import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@common/core/services/guards';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { DesktopLayoutComponent } from './core/components/desktop-layout/desktop-layout.component';
import { SignInComponent } from './core/components/sign-in/sign-in.component';
import { DemoComponent } from './modules/demo/demo.component';

const routes: Routes = [
  { path: '', redirectTo: 'projects', pathMatch: 'full' },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: '',
    component: DesktopLayoutComponent,
    children: [
      {
        path: 'projects',
        loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'demo',
        component: DemoComponent,
      },
      {
        path: 'communication-plan',
        loadChildren: () =>
          import('./modules/communication-plan/communication-plan.module').then(
            m => m.CommunicationPlanModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'changelog',
        loadChildren: () =>
          import('./../../common/libs/bitforce/modules/changelog/bitf-changelog.module').then(
            m => m.BitfChangelogModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
