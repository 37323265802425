import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

import { StoreService } from '@services';
import { eStoreActions } from '@enums';
@Component({
  selector: 'prada-project-mobile-menu',
  templateUrl: './project-mobile-menu.component.html',
  styleUrls: ['./project-mobile-menu.component.scss'],
})
export class ProjectMobileMenuComponent implements OnInit {
  constructor(
    public storeService: StoreService,
    private router: Router,
    public dialogRef: MatDialogRef<ProjectMobileMenuComponent>
  ) {}

  ngOnInit() {}

  gotoChannelGroup(channelGroup) {
    this.storeService.setStore(() => {
      this.storeService.store.channelGroupSlug = channelGroup.slug;
    }, eStoreActions.CHANNEL_GROUP_SLUG);

    this.router.navigate([
      'projects',
      this.storeService.store.brand.slug,
      this.storeService.store.project.id,
      this.storeService.store.moment.slug,
      channelGroup.slug,
    ]);
    this.dialogRef.close();
  }
}
