import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';
import { BitfFile } from '@bitf/core/models/bitf-file.model';

import { FileModel } from '@models';
import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class SectionsService extends BitfApiService {
  constructor(public injector: Injector) {
    // super('sections', injector, environment.mockApiUrl, 'mockDbParser');
    super('sections', injector);
  }

  uploadSectionFiles(files: BitfFile[], id: number): Observable<IBitfApiResponse<FileModel[]>[]> {
    return super.uploadMultiple<FileModel[]>({
      id,
      files,
      fileFormFieldName: 'file',
      relation: 'files',
    } as IBitfApiRequest);
  }
}
