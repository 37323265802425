import { Component, OnInit } from '@angular/core';

import { IBitfMenuItem } from '@interfaces';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';
import { APP_VERSION, BUILD_DATE } from '@env/version';
import { AppSessionService, UiRoleManagerService } from '@services';
import { ERoleActions } from '@web/enums';

@Component({
  selector: 'prada-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  appVersion = APP_VERSION;
  appBuildAt = BUILD_DATE;

  menuItems: Array<IBitfMenuItem> = [
    {
      icon: 'event_note',
      label: bitfToTranslate('WEB.MAIN_MENU.COMMUNICATION_PLAN'),
      url: '/communication-plan',
    },
    {
      icon: 'assignment',
      label: bitfToTranslate('WEB.MAIN_MENU.PROJECTS'),
      url: '/projects',
    },
    // {
    //   icon: 'insert_invitation',
    //   label: bitfToTranslate('WEB.MAIN_MENU.REAL_TIME_SCHEDULING'),
    //   // url: '/real-time-campaing',
    //   url: '',
    // },
    // {
    //   icon: 'list_alt',
    //   label: bitfToTranslate('WEB.MAIN_MENU.APPROVAL_LIST'),
    //   // url: '/approval-list',
    //   url: '',
    // },
  ];

  isOpen = true;

  constructor(
    public appSessionService: AppSessionService,
    private uiRoleManagerService: UiRoleManagerService
  ) {}

  ngOnInit() {
    this.toggleSidenav(this.appSessionService.activeBreakpoints.isDesktop);
    if (this.uiRoleManagerService.canI(ERoleActions.VIEW_SETTINGS)) {
      this.menuItems.push({
        icon: 'settings',
        label: bitfToTranslate('COMMON.LABEL.SETTINGS'),
        url: '/settings',
      });
    }
  }

  toggleSidenav(status?: boolean) {
    if (status) {
      this.isOpen = status;
    } else {
      this.isOpen = !this.isOpen;
    }
    window.dispatchEvent(new Event('resize'));
  }
}
