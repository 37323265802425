<div class="d-flex flex-column p-2">
  <h3>{{ 'COMMON.LABEL.PRODUCT' | translate }}</h3>

  <form [formGroup]="form"
    *ngIf="form">
    <div formGroupName="items">
      <div class="row d-flex align-items-center justify-content-between border-bottom py-2"
        *ngFor="let product of form.get('items').controls; let i = index;"
        [formGroupName]="i">

        <div
          class="col-24 col-sm-5 d-flex flex-column flex-sm-row flex-wrap align-items-center justify-content-center">
          <prada-main-image [image]="product.value.file"
            backgroundSize="contain"></prada-main-image>

          <prada-file-uploader *ngIf="product.value.id"
            class="mt-2"
            [uploadFor]="product.value.model"
            [showDndArea]="false"
            [showFileList]="false"
            [maxNumberOfFiles]="1"
            [concatenateMultipleFiles]="false"
            [extensions]="['png', 'jpg', 'jpeg', 'gif', 'bmp', 'ico', 'tif', 'emf']"
            [uploadOnFilesDropped]="true"
            (startUpload)="onStartMainImageFileUpload()"
            (filesUploaded)="onMainImageFileUploaded($event, product)"></prada-file-uploader>
        </div>
        <mat-form-field appearance="outline"
          class="col-12 col-sm-5 pt-2 pt-sm-0">
          <mat-label>{{ 'COMMON.LABEL.CODE' | translate}}</mat-label>
          <input matInput
            type="text"
            placeholder="{{'COMMON.LABEL.CODE' | translate}}"
            formControlName="code" />
        </mat-form-field>
        <mat-form-field appearance="outline"
          class="col-12 col-sm-5 pt-2 pt-sm-0">
          <mat-label>{{ 'COMMON.LABEL.CATEGORY' | translate}}</mat-label>
          <input matInput
            type="text"
            placeholder="{{'COMMON.LABEL.CATEGORY' | translate}}"
            formControlName="category" />
        </mat-form-field>
        <mat-form-field appearance="outline"
          class="col-24 col-sm-4 pt-2 pt-sm-0">
          <mat-label>{{ 'COMMON.LABEL.PRICE' | translate}}</mat-label>
          <input matInput
            [textMask]="{mask: numberMask}"
            type="text"
            placeholder="{{'COMMON.LABEL.PRICE' | translate}}"
            formControlName="price" />
        </mat-form-field>

        <div
          class="col-24 col-sm-auto d-flex flex-sm-column flex-lg-row flex-wrap justify-content-center align-items-center pt-2 pt-sm-0 px-0">
          <button mat-raised-button
            class="my-1 mx-1"
            color="primary"
            (click)="saveProduct(product, i)">
            {{ 'COMMON.BUTTON.SAVE' | translate}}
          </button>
          <button mat-button
            class="my-1"
            (click)="deleteProduct(product, i)">
            Delete
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="mt-3">
    <button mat-stroked-button
      color="primary"
      (click)="addProduct()">
      {{ 'COMMON.LABEL.ADD_PRODUCT' | translate}}
    </button>
  </div>
</div>