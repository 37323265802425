import { HttpResponse } from '@angular/common/http';

import { BitfRestEnvelopeMapper } from '@bitf/parsers/rest-parser/bitf-rest-envelope.mapper';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

import {
  IBitfEnvelopeMapper,
  IBitfEnvelopeMapperData,
  IBitfApiResponse,
  IBitfApiPagination,
  IBitfApiPPaginationResponse,
} from '@interfaces';

export abstract class BitfPEnvelopeMapper extends BitfRestEnvelopeMapper implements IBitfEnvelopeMapper {
  map({ req, event }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    event = super.map({ req, event });
    const mappedBody: IBitfApiResponse<any> = event.body;
    const originalBody = event.body.originalBody;

    console.log(originalBody);

    if (originalBody != null) {
      const data = originalBody.data;
      const hasItems = bitfGetProp(data, 'items');

      if (hasItems) {
        const { items, ...pagination } = data;
        // NOTE: Map items -> content
        console.log(items);
        mappedBody.content = items;

        mappedBody.pagination = this.mapPagination(pagination);

        // mappedBody.sorting = this.mapSorting(sorting);
      } else if (data) {
        mappedBody.content = data;
      }

      if (originalBody.metadata) {
        mappedBody.metadata = originalBody.metadata;
      }
    }

    return event.clone({
      body: mappedBody,
    });
  }

  mapPagination(pagination: IBitfApiPPaginationResponse): IBitfApiPagination {
    if (!pagination) {
      return;
    }
    const mappedPagination: IBitfApiPagination = {
      first: pagination.hasPreviousPage,
      last: pagination.hasNextPage,
      paginationInfo: pagination.paginationInfo,
      page: pagination.pageIndex,
      size: pagination.pageSize,
      itemsInPage: pagination.count,
      totalItems: pagination.totalCount,
      totalPages: pagination.totalPages,
    };
    return mappedPagination;
  }

  // mapSorting(sortingResponse: IBitfApiDefaultSortingResponse[]): IBitfApiSorting[] {
  //   if (!sortingResponse || !sortingResponse.length) {
  //     return;
  //   }
  //   return sortingResponse.map((sorting: IBitfApiDefaultSortingResponse) => {
  //     return { property: sorting.property, direction: sorting.direction } as IBitfApiSorting;
  //   });
  // }
}
