import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { BitfFormItemComponent } from '../../bitf-form-item.component';

@Component({
  selector: 'bitf-mat-form-item',
  templateUrl: './bitf-mat-form-item.component.html',
  styleUrls: ['./bitf-mat-form-item.component.scss'],
})
export class BitfMatFormItemComponent extends BitfFormItemComponent implements OnInit {
  @Input() matFormFieldClass: string;

  @ViewChild('pickerDate', { static: false })
  pickerDate: MatDatepicker<Date>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  displayFn(option: any): string {
    if (option) {
      const item = this.componentConfig.autocompleteData.find(
        _item => _item.id === (this.componentConfig.useObject ? option.id : option)
      );
      return item && item[this.componentConfig.autocompleteDataKeyLabel || 'label'];
    }
    return '';
  }

  reloadDate(startDate: Date) {
    const currentDate = this.formControl.value;
    if (startDate > new Date(currentDate)) {
      this.formControl.patchValue(startDate);
    }
  }

  toggleDatePicker() {
    if (this.canAction() && this.formItem.isEditable) {
      this.pickerDate.open();
    }
  }
}
