import { BitfStore } from '@bitf/core/models/bitf-store.model';
import { ChannelGroup, Brand, Moment, Project, User } from '@models';

export class Store extends BitfStore {
  user: User;
  project: Project;
  projectMoments: Moment[];
  moments: Moment[];
  momentSlug: string;
  channelGroups: ChannelGroup[];
  channelGroupSlug: string;
  brand: Brand;

  constructor(storeData: any) {
    super(storeData);
  }

  get projectId() {
    return this.project.id;
  }

  get channelGroup() {
    return (
      (this.channelGroups && this.channelGroups.find(item => item.slug === this.channelGroupSlug)) ||
      ({} as any)
    );
  }

  get moment() {
    return (
      (this.projectMoments && this.projectMoments.find(item => item.slug === this.momentSlug)) || ({} as any)
    );
  }
}
