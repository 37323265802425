import { Pipe, PipeTransform } from '@angular/core';
import { bitfBytesPipeFn } from './bitf-bytes.function';
@Pipe({
  name: 'bitfBytes',
})
export class BitfBytesPipe implements PipeTransform {
  transform(bytes: number = 0, precision: number = 2): string {
    return bitfBytesPipeFn(bytes, precision);
  }
}
