import { Injectable } from '@angular/core';

import { IBitfUiRoleManagerService } from '@interfaces';
import { ERoleActions } from '@enums';
import { StoreService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class UiRoleManagerService implements IBitfUiRoleManagerService {
  constructor(private storeService: StoreService) {}

  canI(action, data?: any): boolean {
    switch (action) {
      case ERoleActions.ADD_EDIT_PROJECT:
        return this.storeService.store.user.canEditProject;
      case ERoleActions.ARCHIVE_PROJECT:
        return this.storeService.store.user.canArchiveProject;
      case ERoleActions.PUBLISH_PROJECT:
        return this.storeService.store.user.canPublishProject;
      case ERoleActions.EDIT_INTRO:
        return this.storeService.store.user.canEditIntro;
      case ERoleActions.EDIT_ACTIVITY:
        return this.storeService.store.user.canEditActivity;
      case ERoleActions.EDIT_ACTIVITY_STATUS:
        return this.storeService.store.user.canEditActivityStatus;
      case ERoleActions.EDIT_TARGETS_AND_RESULTS:
        if (data?.readonly) {
          return false;
        }
        return this.storeService.store.user.canEditTargetAndResults;
      case ERoleActions.EDIT_MOMENT:
        return this.storeService.store.user.canEditMoment;
      case ERoleActions.EDIT_FILE:
        return this.storeService.store.user.canEditFile;
      case ERoleActions.VIEW_SETTINGS:
        return this.storeService.store.user.canViewSettings;
      case ERoleActions.EDIT_INFLUENCER_SETTINGS:
        return this.storeService.store.user.canEditInfluencersSettings;
      case ERoleActions.EDIT_PLATFORMS_SETTINGS:
        return this.storeService.store.user.canEditPlatformsSettings;
      case ERoleActions.EDIT_SOCIAL_CALENDAR_SETTINGS:
        return this.storeService.store.user.canEditSocialCalendarSettings;
      case ERoleActions.EDIT_DASHBOARD:
        return this.storeService.store.user.canEditDashboard;
      case ERoleActions.DOWNLOAD_PDF_DASHBOARD:
        return this.storeService.store.user.canDownloadPDFDashboard;
      case ERoleActions.DOWNLOAD_PDF_PROJECT:
        return this.storeService.store.user.canDownloadPDFProject;
      case ERoleActions.DOWNLOAD_EXCEL_COMMUNICATION_PLAN:
        return this.storeService.store.user.canDownloadExcelCommunicationPlan;
      case ERoleActions.VIEW_LOG:
        return this.storeService.store.user.canViewLog;
    }
    return false;
  }
}
