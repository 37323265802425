import { SuperModel } from './super-model.model';

export class SocialBrand extends SuperModel {
  label: string;
  slug: string;
  fields: {
    label: string;
    value: number;
  }[];

  constructor(data: Partial<SocialBrand>) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      label: this.label,
      likes: this.slug,
      fields: this.fields,
    };
  }
}
