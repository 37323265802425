import { Component } from '@angular/core';

import { AppSessionService } from '@services';
import { environment } from '@env/environment';

@Component({
  selector: 'prada-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private appSessionService: AppSessionService) {
    this.appSessionService.init();
    console.log('ENV', environment);
  }
}
